import { ModelWidgetInterface, RelationTypeWith, WidgetHelped, Filterable, CheckRuleByFunction, CheckRuleAgeRange, CheckRuleMessage, CheckRule, ForceListenEvent, CodeItem, CodesFilter, StyledPropsTheme } from "./component-types";
import { ActionFunctionNames, ActionCustomFunc } from "../mobile-component/generic-actions/action-types";
import { Consts } from "../common";
import { FirstProductType } from "./component-types";
import { CoverageItem, MobileButtonProps } from "../mobile-component";

// 单证模板类型
export enum SignatureServerSideDocType {
	CLIENT_NOTICE = '1',
	CONSIGNMENT_AGREEMENT = '2',
	RECEIPT = '3',
	INSURANCE_CUSTOMER_NOTIFICATION = '4',
	ELECTRONIC_INSURANCE_FORM = '5',
	WAIVER_INSURE_LIABILITY = '6',//附加险免除保险人责任条款说明书
	PERSONAL_TAX_RESIDENT = '7',
	PERSONAL_INSURANCE_REMINDER = '8',
	CASE_DETAIL_SELECTION = '9',//方法详情及方案选择表
	FOREIGN_QUESTION_SURVEY = '10',// 非大陆人士问卷,
	LIABILITY_EXEMPTION_CLAUSES = '11', //免除保险人责任条款的说明书,
	CHONGQING_QUESTION_SURVEY = '12', //重庆地区问卷调查
	ELECTRONIC_CONFIRM_FORM = '13', //电子投保确认函
	INSURANCE_AUTOPAY_AUTHORIZATION = '14', //保险款项自动转账授权书
	ELECTRONIC_CONFIRMATION_APPLICATION = '15', //电子投保申请确认书
	WITHDRAW_BEFORE_ISSUE = '16', //承保前撤件
	RELATION_DECLARATION = '18',//关系声明
	PREMIUM_TRANSFER_STATEMENT = '19', //保险费转入声明
	CHONGQING_RISK_HINT = '20', //风险提示
	BABY_QUESTION_SURVEY = '24',//婴幼儿健康问卷
	ALTERNATIVE_INSURANCE_LETTER = '26',//隔代投保书单证
	MEDICAL_SPECIAL_DECLARATION = '30', //医疗保险特别约定
	QUOTE_MODIFY_ADDTIONAL_DECLARATION = '31', //投保或合同变更声明
	POLICY_ISSUE_LETTER = '32', //问题件
	INSURANCE_INSTRUCTIONS = '33', //投保须知书
	PERSONAL_INSURANCE_REMINDER_CHONGQING = '34', // 人身投保提示书重庆版
	PERSON_INFORMATION_AUTHORIZATION = '37',//投保声明与授权
	AGENT_DECLARACTIONS = '38', //业务员告知
	FAMILY_PROPOSAL = '39',//家庭保险投保单
	BENEFICIARY_EXTRAL_DECLARATION = '40',//受益人信息补充告知书
	APPLICATION_CHANGE_APPLY = '41',//投保要约变更申请书
	/** 客户告知书V2 */
	CLIENT_NOTICE_V2 = '42',
	/** 保险经纪委托协议书V2 */
	CONSIGNMENT_AGREEMENT_V2 = '43',
	APPLICATION_CANCEL_APPLY = '44',//投保要约撤销申请书
	/** 保险专业代理（销售）公司客户告知书 */
	INSURANCE_CLIENT_NOTICE = '45',
	/** 保险销售人员自保、互保件告知书-东莞 */
	SELF_INSURANCE_CLIENT_NOTICE_DG = '46',
	/** 保险销售人员自保件告知书-河南 */
	SELF_INSURANCE_CLIENT_NOTICE_HN = '47',
	/** 人身保险投保提示书-江苏 */
	PERSONAL_INSURANCE_REMINDER_JS = '48',
	MINORS_AUTHORIZATION_DECLARATION = '49', // 未成年投保授权声明书
	HIGH_AMOUNT_QUESTION = '50', // 高保额财务问卷
	/** 全国风险评测问卷 */
	RISK_NOTICE_QUESTIONNAIRE = '51',
	SPECIAL_AREA_INSURANCES_SPECIFICATION = '52',
    BENEFIT_DEMO = '53', //利益演示
	PRODUCT_STATEMENT = '55',//产品说明书
	DR_INFROM_SUPPLEMENT = '56', //“双录”影像资料补充告知书
	PERSONAL_INSURANCE_REMINDER_SZ = '57',// 深圳地区人身保险产品风险提示书
	POLICY_INFORM_ITEM = '58',//投保告知事项
	HEALTH_CONTROL = '59',//健康管理服务协议
	UNIVERSAL_RISK_NOTIFICATION = '64',//万能险风险告知书
	RENEWAL_CHOICE_STATEMENT_LETTER = '65',//续保选择声明函
    RISK_ANALYZE_QUESTIONNAIRE = '66',//需求分析及风险承受能力测评问卷
    AUTOMATIC_PREMIUM_DEDUCTION = '67',//自动抵交保费声明
    CUSTOMER_INFORMATION_AUTHORIZATION = '68',//客户信息授权协议书

}

// 保单模型 ********************************************
export enum Gender {
	MALE = 'M',
	FEMALE = 'F'
}

export enum CoverageType {
	MAIN = 'M',
	RIDER = 'R'
}

export enum ParticipantIdType {
	/** 身份证 */
	ID_CARD = 1,
	/** 户口本 */
	FAMILY_REGISTER = 2,
	/** 出生证明 */
	BIRTH_CERTIFICATE = 3,
	/** 驾照 */
	DRIVE_LICENSE = 4,
	/** 军官证 */
	MILITARY_ID = 5,
	/** 护照 */
	PASSPORT = 6,
	/** 港澳通行证 */
	HK_PASS = 7,
	/** 台湾通行证 */
	TW_PASS = 8,
	/** 外国护照 */
	// FOREIGN_PASSPORT = 9,
	/** 居住证 */
	FOREIGN_REGISTER_CARD = 10,
	/** 港澳居民来往内地通行证 */
	HK_MAINLAND = 11,
	/** 台湾居民来往大陆通行证 */
	TW_MAINLAND = 12,
	/** 香港/澳门居民居住证 */
	HK_RESIDENT_PERMIT = 13,
	/** 台湾居民居住证 */
	TW_RESIDENT_PERMIT = 14,
	/** 外国人永久居留身份证 */
	PERMANENT_RESIDENT_PERMIT = 15,
	/** 警官证 */
	POLICE_OFFICER_CERTIFICATE = 16,
	/** 临时身份证 */
	TEMPORARY_ID_CARD = 17,
	/** 士兵证 */
	SOLDIER_ID_CARD = 18,
	/** 军官退休证 */
	RETIRE_MILITARY_ID = 19,
	// 港澳台居民居住证
	AIWAN_GANGAO_LIVING_CARD = 20,
	// 中国护照
	CHNPASSPORT = 23,
	/** 港澳居民来往内地通行证（非中国籍） */
    HK_MACAO_RESIDENCE_PERMIT_NOCHN = 25,
	//监护人证件
	INSURED_PARENTS_IDTIFY = 200,
	/** 其他证件 */
	OTHERS = 99
}

export type ParticipantDeclaration = {
	/** 告知问题的版本 */
	version?: string;
	/** 告知问题的代码 */
	code?: string;
	/** 告知问题的备注 */
	remark?: string;
	/** 告知层级，告知的父告知项的代码*/
	parentCode?: string;
	/** 告知问题的具体内容 */
	content?: string;
	/** 告知的选项答案 */
	answer?: string | boolean;
	/** 告知的目标客户 */
	target?: string;
	/** 告知的类别 */
	notifyTo?: string;
	[propName: string]: any;
};

export enum PolicyDocumentType {
	/** 身份证 */
	ID_CARD = 1,
	/** 户口本 */
	FAMILY_REGISTER = 2,
	/** 出生证明 */
	BIRTH_CERTIFICATE = 3,
	/** 驾照 */
	DRIVE_LICENSE = 4,
	/** 军官证 */
	MILITARY_ID = 5,
	/** 护照 */
	PASSPORT = 6,
	/** 港澳通行证 */
	HK_PASS = 7,
	/** 台湾通行证 */
	TW_PASS = 8,
	/** 外国护照 */
	// FOREIGN_PASSPORT = 9,
	/** 居住证 */
	FOREIGN_REGISTER_CARD = 10,
	/** 港澳居民来往内地通行证 */
	HK_MAINLAND = 11,
	/** 台湾居民来往大陆通行证 */
	TW_MAINLAND = 12,
	/** 香港/澳门居民居住证 */
	HK_RESIDENT_PERMIT = 13,
	/** 台湾居民居住证 */
	TW_RESIDENT_PERMIT = 14,
	/** 外国人永久居留身份证 */
	PERMANENT_RESIDENT_PERMIT = 15,
	/** 警官证 */
	POLICE_OFFICER_CERTIFICATE = 16,
	/** 临时身份证 */
	TEMPORARY_ID_CARD = 17,
	/** 士兵证 */
	SOLDIER_ID_CARD = 18,
	/** 军官退休证 */
	RETIRE_MILITARY_ID = 19,
	// 港澳台居民居住证
	AIWAN_GANGAO_LIVING_CARD = 20,
	// 中国护照
	CHNPASSPORT = 23,
	OTHERS = 99,

	/** 签名 */
	BANK_CARD = '-1',
	SIGNATURE = '-2',

	// important!!!， 以后所有保司单证类型由BA确认，AD不允许擅自添加 by tim.zhou
    RISK_HINT = '-8',//风险提示语录（手抄）
	PERSONAL_INSURANCE_TIPS_PDF = '-9',//全国性人身投保提示书_PDF
	// 下面是BA确认的单证类型
	BUSINESS_PROPOSAL_SIGN_HOLDER = '-10', //业务投保单投保人签名PDF
	BUSINESS_PROPOSAL_SIGN_INSURED = '-11', //业务投保单投被保人签名PDF
	BUSINESS_PROPOSAL_SIGN_AGENT = '-12',/** 业务投保单投代理人签名PDF */
    /** 其他资料 */
    OTHER_DOCUMENT = '-17',
    INSURANCE_CLIENT_NOTICE_PDF_JS = '-46',/** 江苏保险专业代理（销售）公司客户告知书 */
	/** 特别选择权理财投资申请书 */
	SPECIAL_RIGHT_FINANCIAL_INVESTMENT_APPLICATION = '-53',
	/** 银行账户变更确认书 issues_19722 */
	BANK_ACCOUNT_CHANGE_CONFIRM = "-86",
	/** 回执 */
	RECEIPT_SIGNATURE = '-89',
	// 人身保险电子投保单
	ELECTRONIC_INSURANCE_FORM = '-94',
	/**个人税收声明PDF-投被保人统一用一个 */
	PERSONAL_TAX_PDF = '-97',
	/**个人税收声明投保人签名PDF-投保人持有 */
	PERSONAL_TAX_PDF_HOLDER = '-97',
    QUOTE_OR_MODIFY_ADDTIONAL_DECLARATION = '-104',//投保或合同变更补充声明 issues/19722
	LIABILITY_EXEMPTION_CLAUSES_SIGNATURE_PDF = '-107',//免除保险人责任条款说明书
	ELEC_POLICY_CONFIRMATION_SIGNATURE = '-108',//电子投保确认书
	/**投保人声明 */
    PROPOSER_DECLARATION_AGENT_PROPOSER_PDF = '-127',
	/** 客户告知书签名 */
	CLIENT_NOTICE_SIGNATURE_PDF = '-132',
	/** 保险经纪服务委托合同签名 */
	CONSIGNMENT_AGREEMENT_SIGNATURE_PDF = '-137',
	RELATIONSHIP_CERTIFICATE_PROPOSER_PDF = '-415',//关系证明PDF
	RELATIONSHIP_CERTIFICATE_PICTURE = '-418',//关系证明图片
	AGENT_DECLARATION_AGENT_PDF = '-427',//业务员告知书
	WITHDRAW_PROPOSAL_PROPOSER_PDF = '-433',//在线撤件_PDF


    PRODUCT_INSURANCE_NOTICE_PDF = '-494',//保单级的投保告知/投保须知
    CHANGE_BANK_ELECTRONIC_INSURANCE_FORM = '-501',//变更卡号投保单
    AUTOMATIC_PREMIUM_DEDUCTION = '-531',//自动抵交保费声明
	/**保险款项自动转账授权书*/
	BANK_AUTO_TRANSFER_AUTHORIZATION_SURVEY = "-805",//保险款项自动转账授权书
	/**免除保险人责任条款的说明书 */
	LIABILITY_EXEMPTION_CLAUSES = '-811',
	INSURANCE_TIPS_PICTURE = '-1002',//投保提示书影像-河南非双录

	RENEWAL_CHOICE_STATEMENT_LETTER = '-1012',//续保选择声明函


	// 双录需要传递的非签字文件类型
	/** 投保声明与授权 */
	DECLARATION_AND_AUTHORIZATION = '-1019',
    /** 保险消费者适当性评估问卷 */
	INSURANCE_ASSESSMENT_QUESTIONNAIRE = '-1028',
	//风险测评问卷
    RISK_ASSESSMENT_QUESTIONNAIRE = '-1102',
	MAIN_COVERAGE_PRODUCT_SPECIFICATIONS = '-1200', // 主险产品说明书
	MAIN_COVERAGE_INSURANCE_TERMS = '-1201', // 险种详细条款
	DR_INFORM_SUPPLEMENT = '-1202',//双录影像
	POLICY_INFORM_ITEM = '-1203',//投保告知事项
	INSURANCE_NOTIFICATION = '-1204',//智能核保问卷
	PERSON_INFORMATION_AUTHORIZATION = '-1205',//个人信息处理授权书
	UNIVERSAL_RISK_NOTIFICATION = '-1206',//万能险风险告知书
    CUSTOMER_NOTIFY_PRODUCT = '-1207', //产品级别告知书
	HEALTH_CONTROL = '-1208',//健康管理服务协议
	CUSTOMER_INFORMATION_AUTHORIZATION = '-1211',//客户信息授权协议书
    INSURANCE_CLIENT_NOTICE_PDF = '-2005',/** 保险专业代理（销售）公司客户告知书 */
    RISK_ANALYZE_QUESTIONNAIRE = '-2012',//需求分析及风险承受能力测评问卷
	UNREGISTERED_CHILDREN_INSURANCE_INSTRUCTIONS= '-2017', //未上户口儿童投保说明
	CERTI_EXPLAIN= '-2018', //证件证明文件
	// ================分割线=========================================
	// 下面是废弃的单证类型，为了兼容老保司，故保留

	BUSINESS_SIGN_HOLDER = '-10-1', //业务投保单投保人签名PDF
	BUSINESS__SIGN_INSURED = '-11-1', //业务投保单被保险人签名

	/** 人身保险产品风险提示书*/
	PERSONAL_INSURANCE_PRODUCT_REMINDER_PDF = '-20',

	BABY_QUESTION_SURVEY_SIGNATURE_PDF = '-38',

	AGENT_DECLARATION_CONFIRMATION_AGENT_PDF = '-45',
	ELEC_POLICY_CONFIRMATION_AGENT_PROPOSER_PDF = '-110',

	/** 人身投保提示书代理人&投保人PDF*/
	PERSONAL_INSURANCE_REMINDER_AGENT_HOLDER_PDF = '-71',
	/**个人税收声明投保人签名PDF-被保人持有 */
	PERSONAL_TAX_PDF_INSURED = '-98',

	MEDICAL_DOCUMENT = '-117',//病例资料

	/** 客户告知书签名 */
	CLIENT_NOTICE_SIGNATURE = '-129',
	CLIENT_NOTICE_SIGNATURE_IMAGE = '-130',
	CUSTOMER_NOTIFY_AGENT_PDF = '-131',//客户告知书
	/** 保险经纪服务委托合同签名 */
	CONSIGNMENT_AGREEMENT_SIGNATURE = '-134',
	CONSIGNMENT_AGREEMENT_SIGNATURE_IMAGE = '-135',
	CONSIGNMENT_AGREEMENT_SIGNATURE_PDF_HOLDER = '-137-1',
	// 个险人身险投保提示书
	PERSONAL_INSURANCE_NOTICE = '-136',
	// 人身保险投保单
	LIFE_INSURANCE_POLICY = '-137',
	/**人身保险投保提示书*/
	INSURANCE_NOTICE_SIGNATURE = '-139',
	/**免除保险人责任条款说明书*/
	WAIVER_INSURER_LIABILLITY_SIGNATURE = '-140',
	//未成年投保授权书
	UNDERAGE_PROPOSAL_IMAGE = '-199',
	//被保险人父母身份证明
	INSURED_PARENTS_IDTIFY = '-200',
	//被保险人母亲/父亲身故证明
	INSURED_PARENTS_DEAD_IDTIFY = '-201',
	//监护人关系证明
	INSURED_RELATIONSHIP_IDTIFY = '-202',
	//监护授权委托协议
	TUTELAGE_AGREEMENT = '-203',
	//授权人身份证明文件
	AUTHOR_IDTIFY = '-204',
	//被保险人父母与被保险人关系证明
	INSURED_PARENTS_RELATIONSHIP_IDTIFY = '-205',
    GENERATION_SKIP_STATEMENT_PDF = '-205',
    PROPOSAL_CONFIRM_SIGN_HOLDER = '-206', //业务投保单确认书投保人签名PDF
	PROPOSAL_CONFIRM_SIGN_INSURED = '-207', //业务投保单确认书投被保人签名PDF
	PROPOSAL_SIGN_HOLDER = '-208', //业务投保单确认书投保人签名PDF
	PROPOSAL_SIGN_INSURED = '-209', //业务投保单确认书投被保人签名PDF

	ASSIGN_BENEFICIARY_ADDITIONAL_INFO_DECLARATION_AGENT_PDF = '-412', // 指定受益人补充信息告知书

	/** 人脸识别截图（新） */
	FACE_RECOGNIZE_CAPTURE = '-600',
	/** 人脸识别 */
	FACE_RECOGNIZE = '-601',
	/** 自保件互保件（东莞/河南） SPECIAL_AREA_INSURANCES_TIPS_PDF */
	SELF_INSURANCE_CLIENT_NOTICE_PDF = '-717',
	// 投保人与被保险人、受益人关系声明
	RELATION_POLICY_HOLDER_INSURED_BENEFICIARY_DECLARATION = '-718',
	/**非大陆人士问卷 */
	FOREIGN_QUESTION_SURVEY = '-801',
	/**重庆地区问卷*/
	CHONGQING_QUESTION_SURVEY = '-804',
	/**重庆地区风险提示*/
	CHONGQING_RISK_HINT = '-807',
	/** 被保险人非大陆人士问卷 */
	FOREIGN_QUESTION_SURVEY_INSURED = '-808',
	/**方法详情及方案选择表 */
	CASE_DETAIL_SELECTION = '-812',
	HOUSEHOLD_REGISTER_HEAD_PAGE_IMAGE = '-813',//被保人户主影像
	/**婴幼儿调查问卷 */
	BABY_QUESTION_SURVEY = '-820',

	LIABILITY_EXEMPTION_CLAUSES_FJ1 = '-821',
	/** 人身保险投保提示书 */
	PERSONAL_INSURANCE_REMINDER = '-825',
	INSURANCE_INSTRUCTIONS_PDF = '-826', // 投保须知书
	RISK_HINT_FOR_HOLDER = '-827',

	INSURED_CONFIRM_SIGN_IMAGE = '-1000',//被保人确认函，信息确认签字
	INSURED_CONFIRM_PDF = '-1001',//被保人确认函，信息确认签字


	INSURANCE_TIPS_SIGN_PICTURE = '-1003',//投保提示书签字影像
	ELECTRONIC_INSURANCE_PDF = '-1010',//电子投保单+自拍
	MEDICAL_DECLARATION = '-1011',//医疗特约
	ELECTRONIC_INSURANCE_HOLDER_FACE_IMAGE = '-155',//电子投保单投保人自拍
	QUOTE_MODIFY_ADDTIONAL_DECLARATION_INSURED = '-1014',//投保或合同变更补充声明被保人
	QUOTE_MODIFY_ADDTIONAL_DECLARATION_AGENT = '-1015',//投保或合同变更补充声明代理人
	ELECTRONIC_INSURANCE_INSURED_FACE_IMAGE = '-1016',//电子投保单被保人自拍
	CHECK_ORDER_PAGE = '-1017', // 核对订单
	PRODUCT_IMPORTANT_MATTERS = '-1018',// 重要事项告知书

	HOLDER_INSURED_CONFIRM_BANK = '-1061',//变更银行卡投保人签字

	/** 特殊区域保险业务投保说明 */
	SPECIAL_AREA_INSURANCES_SPECIFICATION = '-1087',
	/** 投被关系影像 */
	HOLDER_INSURED_RELATION = "-1088",
	/** 被受关系影像 */
	INSURED_BENEFICIARY_RELATION = "-1089",
	FAMILY_INSURANCE_POLICY = '-1090', // 家庭保险投保单
	POLICY_CHANGE_APPLY = '-1091', // 投保要约变更申请书
	POLICY_CANCEL_APPLY = '-1092', // 投保要约撤销申请书
	/** 复联福星高照新增高保额财务问卷投保人签名PDF */
	HIGH_SUMINSURED_QUESTION_SIGN_HOLDER = '-1095',
	/** 复联福星高照新增高保额财务问卷被保人签名PDF */
	HIGH_SUMINSURED_QUESTION_SIGN_INSURED = '-1097',
	/** 复联福星高照新增未成年人投保授权声明书投保人签名PDF */
	UNDERAGE_PROPOSAL_SIGN_HOLDER = '-1096',
	/** 复联福星高照新增未成年人投保授权声明书被保人签名PDF */
	UNDERAGE_PROPOSAL_SIGN_INSURED = '-1098',
	FACE_DETECT_CAPTURE = '-1100',//人脸识别过程中的截图
	/** 保险专业代理（销售）公司客户告知书 -- 江苏 */
	CLIENT_NOTICE_SIGNATURE_PDF_JS = '-1101',
	INSURED_FACE_DETECT_CAPTURE = '-1101',//人脸识别过程中的截图
	/** 全国风险评测问卷 */
	RISK_NOTICE_QUESTIONNAIRE = '-1102',


	POLICY_ISSUE_LETTER = '-1020',//函件pdf
	PREVIEW_POLICY_ISSUE_LETTER = '-1021',//函件pdf
	WITHDRAW_SIGNATURE = '-1042',//承保前撤件申请书签名影像

	ALTERNATIVE_INSURANCE_LETTER_PDF = '-800',// 隔代投保同意书
	BABY_QUESTION_SURVEY_PDF = '-824', // 婴幼儿健康问卷
}

export type PolicyDocument = {
	documentType: PolicyDocumentType | string;
	front?: boolean;
	url?: string;
	uri?: string;
	signedDocId?: string | number;
	isComplete?: boolean;
	readComplete?: boolean;
	signDate?: string;
	previewPdfUrl?: string;
	coverageCode?: string;
    documentId?: number;
    type?: string;
	extensionData?: any;
};
export type SignatureDocument = {
	isComplete?: boolean;
	image?: string;
	signDate?: string;
	signImage?: string;
	signComplete?: boolean;
	given?: {};
	coverageCode?: string;
};

export enum SignatureStatus {
	/** 待签名 */
	PRE_SIGN = '1',
	/** 获取验证码 */
	SEND_CODE = '2',
	/** 已短信验证 */
	VALIDATION = '3',
	/** 已签名 */
	SIGNED = '4',
	/** 重签 */
	RE_SIGN = '5'
}

export enum SmsType {
	/**客户签名短信 */
	NOTIFY_CUSTOMER_SIGN = '1',
	/**通知代理人投保人签名成功,被保人继续签名 */
	NOTIFY_AGENT_PH_SIGN_SUCCESS_AND_CONTINUE = '2',
	/**通知代理人被保人签名成功 */
	NOTIFY_AGENT_INSURED_SIGN_SUCCESS = '3',
	/**通知代理人投保人签名链接失效 */
	NOTIFY_AGENT_PH_SIGN_OVERDUE = '4',
	/**通知代理人被保人签名链接失效 */
	NOTIFY_AGENT_INSURED_SIGN_OVERDUE = '5',
	/**通知代理人投保人签名成功 */
	NOTIFY_AGENT_SIGN_SUCCESS = '6',
	/**通知代理人投保人签名回执成功 */
	NOTIFY_AGENT_RECEIPT_SIGN_SUCCESS = '7',
	/**商城客户短信 */
	SIGNIN_CUSTOMER_FOR_MALL = '8',
	/**分享链接客户验证短信 */
	POLICY_SHARE_CUSTOMER_FOR_MALL = '9',
	/**客户人脸识别短信 */
	NOTIFY_CUSTOMER_FACERECONGNIZE = '10',
	/**分享保单链接客户验证短信 */
	POLICY_VIEW_SHARE_CUSTOMER_FOR_MALL = '11',
	/**C端公众号绑定短信 */
	REGISTER_CUSTOMER_FOR_TOC = '12',
	/**C端公众号解绑短信 */
	UNBIND_CUSTOMER_FOR_TOC = '13',
	/**通知代理人,被保人身份验证成功 */
	POLICY_CHECK_INSURED_FOR_CUSTOMER = '14',
	/**通知投保人,被保人身份验证成功 */
	POLICY_CHECK_INSURED_FOR_AGENT = '15',
	/**投保人撤单验证短信 */
	POLICY_WITHDRAW_FOR_HOLDER_SMS_SEND = '20'
}


export enum SignatureSource {
	POLICY = "POLICY", //"保单"),
	ADDON = "ADDON",//"永达理单证"),
	FACERECONGNIZE = "FACERECONGNIZE", //"人脸识别" );
}


export type ParticipantSignatureDocument = {
	documentType: any;
} & SignatureDocument;
export type ParticipantSignatureCADocument = {
	documentType: any;
	signedDocId?: string | number;
	ossLocation?: string;
	url?: string;
	uri?: string;
};

export type ParticipantExtensionDataModel = {
	/**与投保人关系 */
	relationWithPH?: string;
	/** 证件有效期起期 */
	idValidStart?: string;
	/** 证件有效期止期 */
	idValidTill?: string;
	/** 证件有效期止期是否长期（Y:N） */
	idLongTermIndi?: string;
	/** 婚姻状态,参考婚姻状态，参考职业类别 */
	maritalStatus?: string;
	/** 职业类别, 参考职业类别 */
	jobCategory?: string;
	/** 职业类别code*/
	jobCateCode?: string;
	/** 职业类别名字 */
	jobCateName?: string;
	/** 身高 */
	height?: string;
	/**体重 */
	weight?: string;
	/** 年收入(元) */
	annualIncome?: string | number;
	/** 收入来源 */
	incomeSource?: string | number | null;
	/** 是否吸烟(Y:N) */
	smokingIndi?: string;
	/** 是否有社保(Y:N) */
	socialInsuranceIndi?: string;
	/** 告知 */
	declarations?: ParticipantDeclaration[];
	/** 上传文档 */
	documents?: (
		| PolicyDocument
		| ParticipantSignatureDocument
		| ParticipantSignatureCADocument)[];
	/** 国籍*/
	nationality?: string;
	/** 年龄 */
	age?: number | string;
	/** 工作单位 */
	workUnit?: string;
	/** 省份名字 */
	provinceName?: string;
	/** 城市名字 */
	cityName?: string;
	/** 地区名字 */
	regionName?: string;
	/** 工作省份code */
	workProvinceCode?: string;
	/** 工作省份 */
	workProvinceName?: string;
	/** 工作城市code */
	workCityCode?: string;
	/** 工作城市 */
	workCityName?: string;
	/** 工作地区code */
	workRegionCode?: string;
	/** 工作地址 */
	workRegionName?: string;
	/** 工作详细地址 */
	workAddressLine1?: string;
	/** 工作邮编 */
	workPostCode?: string;
	/** 家庭省份code */
	familyProvinceCode?: string;
	/** 家庭省份 */
	familyProvinceName?: string;
	/** 家庭城市code */
	familyCityCode?: string;
	/** 家庭城市 */
	familyCityName?: string;
	/** 家庭地区code */
	familyRegionCode?: string;
	/** 家庭地址 */
	familyRegionName?: string;
	/** 家庭详细地址 */
	familyAddressLine1?: string;
	/** 家庭邮编 */
	familyPostCode?: string;
	/** 居民税收身份证明 */
	taxDweller?: TaxDweller;
	/** 台湾身份证 */
	twCertiCode?: string;

	/** 电投签名相关字段 */
	signature?: {
		/** 分享签字id */
		signatureId?: string | number;
		/** 分享签字处理状态 */
		signatureStatus?: SignatureStatus;
		url?: string;
		smsValidateId?: string | number;
	};
	/** 一字多签被保人签名是否完成 */
	signComplete?: boolean;
	/** 一字多签被保人签名base64 */
	signImage?: string;
	/** 一字多签被保人签名日期 */
	signDate?: string;

	[propName: string]: any;
}

export type Participant = {
	/** 名. 中文为全名 */
	firstName?: string;
	/** 中间名 */
	middleName?: string;
	/** 姓 */
	lastName?: string;
	/** 全名 */
	fullName?: string;
	/** 性别 */
	gender?: Gender | string;
	/** 出生日期 */
	dateOfBirth?: string;
	/** 证件类型 */
	idType?: ParticipantIdType | string | null;
	/** 证件号码 */
	idNo?: string;
	/** 移动电话 */
	mobile?: string;
	/** 邮件地址 */
	email?: string;
	/** 国家代码 */
	countryCode?: string;
	/** 省份代码 */
	provinceCode?: string;
	/** 城市代码 */
	cityCode?: string;
	/** 区县代码 */
	regionCode?: string;
	/** 地址1 */
	addressLine1?: string;
	/** 地址栏2 */
	addressLine2?: string;
	/** 地址栏3 */
	addressLine3?: string;
	/** 邮政编码 */
	postCode?: string;
	/** 人扩展字段 */
	extensionData?: ParticipantExtensionDataModel;
};

export type TaxCountry = {
	/** 税收地区 */
	country?: string;
	/** 税收地区(码表l的值) */
	countryName?: string;
	/** 纳税人识别号 */
	taxNum?: string;
	/** 不能识别原因 1-居民国/地区不发放纳税人识别号，2-账户持有人未能取得纳税人识别号 */
	reason?: string;
	/** 具体原因 */
	memo?: string;
	/** 是否有纳税识别号（Y:N） */
	hasTaxNo?: string;
}

export type TaxDweller = {
	/** 居民税收身份 */
	taxType?: string;
	/** 名 */
	firstName?: string;
	/** 姓 */
	lastName?: string;
	/** 现居地国家英文 */
	currentCountryEn?: string;
	/** 现居地国家英文(码表l的值) */
	currentCountryEnName?: string;
	/** 现居地省份英文 */
	currentProvinceEn?: string;
	/** 现居地城市英文 */
	currentCityEn?: string;
	/** 现居地地区英文 */
	currentDetailEn?: string;
	/** 现居地国家中文 */
	currentCountry?: string;
	/** 现居地国家中文(码表l的值) */
	currentCountryName?: string;
	/** 现居地省份中文 */
	currentProvince?: string;
	/** 现居地省份中文(码表l的值) */
	currentProvinceName?: string;
	/** 现居地城市中文 */
	currentCity?: string;
	/** 现居地城市中文(码表l的值) */
	currentCityName?: string;
	/** 现居地地区中文 */
	currentDetail?: string;
	/** 现居地地区中文(码表l的值) */
	currentDetailName?: string;
	/** 出生地国家英文 */
	birthCountryEn?: string;
	/** 出生地国家英文(码表l的值) */
	birthCountryEnName?: string;
	/** 出生地省份英文 */
	birthProvinceEn?: string;
	/** 出生地城市英文 */
	birthCityEn?: string;
	/** 出生地地区英文 */
	birthDetailEn?: string;
	/** 出生地国家中文 */
	birthCountry?: string;
	/** 出生地国家中文(码表l的值) */
	birthCountryName?: string;
	/** 出生地省份中文 */
	birthProvince?: string;
	/** 出生地省份中文(码表l的值) */
	birthProvinceName?: string;
	/** 出生地城市中文 */
	birthCity?: string;
	/** 出生地城市中文(码表l的值) */
	birthCityName?: string;
	/** 出生地地区中文 */
	birthDetail?: string;
	/** 出生地地区中文(码表l的值) */
	birthDetailName?: string;
	/** 税收地址 */
	taxCountry?: TaxCountry[];
}

export enum PolicyCustomerType {
	/** 投保人 */
	HOLDER = 1,
	/** 被保人 */
	INSURED = 2,
	/** 受益人 */
	BENEFICIARY = 3,
	/** 预约客户 */
	PROSPECT = 5,
	/** 监护人 */
	GUARDIAN = 6
}

export enum InsuredType {
	VEHICLE = "1",// "Vehicle Insured"), //
	MARINE = "2",// "Marine Insured"), //
	ADDRESS = "3", // "Address Insured"), //
	CONTRACT = "4", // "Contract Insured"), //
	PERSON = "5", // "Person Insured");
}



export type InsuredParticipant = {
	/** 被保人受益人主键  如果是第三方输入数据, 该字段无需填写. */
	insuredParticipantId?: number;
	/** 参与者类型 */
	customerType: PolicyCustomerType.INSURED | string;
	extensionData?: {
		partySerialId?: string | number;
		/** 与投保人关系 */
		relationWithPH?: RelationTypeWith;
		/**附加被保人与主被保人关系，受益人与被保人关系 */
		relationWithInsured?: string;
		signatureEnabled?: boolean;
		csModel?: CSModel;
	};
} & Participant;

export enum BeneficiaryType {
	/**受益人 暂无明确类型*/
	BENEFIT = '0',
	/** 死亡受益人、身故受益人 */
	DEATH = '1',
	/**生存受益人*/
	SURVIVAL = '2',
	/** 满期收益金 */
	MATURITY_BENEFIT = '3',
	/** 祝寿金受益人 */
	BIRTH_CONGRATULATE = '4',
	/** 年金受益人 */
	ANNUITY = '7',
	/** 医疗给付受益人 */
	MEDICAL_BENEFICIARY = '9',
}

export type BeneficiaryParticipant = {
	/** 序列号 */
	insuredParticipantId?: number;
	/** 参与者类型 */
	customerType: PolicyCustomerType.BENEFICIARY | string;
	extensionData?: {
		partySerialId?: string | number;
		/** 受益类型 */
		beneficiaryType?: BeneficiaryType;
		/** 是否法定受益人 */
		legalIndi?: 'Y' | 'N' | null;
		/** 是否同投保人 */
		sameWithPH?: 'Y' | 'N' | null;
		/** 受益顺序 */
		benefitOrder?: number | string;
		/** 受益比例 */
		benefitRatio?: number;
	};
} & Participant;

export type GuardianParticipant = {
	/** 序列号 */
	insuredParticipantId?: number;
	/** 参与者类型 */
	customerType: PolicyCustomerType.GUARDIAN | string;
	extensionData?: {
		/** 与被保人的关系, 参考人际关系*/
		otherRelation?: string;
		/** 与被保人关系具体描述，枚举值为999-其他时，使用此处的汉字*/
		otherRelationRemark?: string;
	}
} & Participant;

// 为了方便ts声明，建立统一对象
export type GeneralParticipant = InsuredParticipant | BeneficiaryParticipant | GuardianParticipant;

export type CoverageExtensionDataModel = {
	/** 险种类型, R: 附加险, M: 主险 */
	coverageType?: 'R' | 'M';
	/** 缴费期间 */
	installment?: string;
	/**保障期限 */
	coveragePeriod?: string;
	/** 年金/生存金/满期金领取期间, 参考领取期间 */
	payPeriod?: string;
	/** 年金领取方式 参考领取方式 1-年领，4-月领 */
	payPeriodType?: string;
	/** 保证领取期间 */
	payInsure?: string;
	/**
	 * @deprecated
	 * 保证领取期间 */
	guaranteePeriod?: string;
	/** 年金领取方式 */
	annuityType?: string;
	/** 生存金领取方式 */
	survivalBenefitType?: string;
	/** 保额 */
	sumInsured?: string | number | null;
	/**档次 */
	benefitLevel?: string | number;
	/**
	 * @deprecated
	 * 档次
	 */
	benefitlevel?: string | number;
	/**份数 */
	unit?: string | number;
	/** 是否自动续保 */
	autoRenewalIndi?: 'Y' | 'N' | null | string;
	/** 首年保费 */
	firstYearPrem?: string | number;
	/** 交费频率 */
	paymentFreq?: string;
	/** 外部保司code */
	externalCode?: string;
	/** 是否投保人豁免 */
	isWaiver?: string;
	/** 险种是否被选择 */
	enabled?: boolean;
	/** 责任集合(liability). 参考责任集合 */
	liabilityList?: LiabilityModel[];
	/** 计划 */
	planCode?: string;
	/** 文档 */
	documents?: (
		| PolicyDocument
		| ParticipantSignatureDocument
		| ParticipantSignatureCADocument)[];
	/** 额外的序列号 */
	coverageSeriaId?: number;
	/** 不知 */
	selfPaymentType?: string;
	/** roleId */
	roleId?: string;
	// 排序依据
	displayOrder?: number;
	[propName: string]: any;
}

export type CoverageModel = {
	/**险种主键 */
	coverageId?: string;
	/** 险种代码 */
	coverageCode?: string;
	/** 险种名称 */
	coverageName?: string;
	/** 险种费用. 参考险种费用模型 */
	coverageFee?: CoverageFeeModel;
	extensionData?: CoverageExtensionDataModel;
	[propName: string]: any;
};

export type LiabilityModel = {
	/** 责任代码 */
	liabilityCode: string;
	/** 责任名 */
	liabilityName?: string;
	/** 是否勾选了该责任 */
	selected?: 'Y' | 'N' | true | false | null;
	value?: 'Y' | 'N' | true | false | null;
	/** 保司责任代码 */
	externalCode?: string;
	canSelect?: string;
	// 没办法才加
	name?: string;
	/** 计划 */
	plan?: string;
	/** 保障期间 */
	coveragePeriod?: string;
	/** 缴费期间 */
	installment?: string;
	/** 是否趸交 */
	singleInstallment?: boolean;
	/** 档次，计划*/
	benefitLevel?: string | number;
	/** 免赔额 */
	deductible?: number | string;
	/** 保费 */
	premium?: string;
	/** 首年保费 */
	firstYearPremium?: string;
	/** 保额 */
	sumInsured?: string | number | null;
	/** 份数 */
	unit?: string;
	/** 责任费用 */
	liabilityFee?: LiabilityFeeModel;
}

export type InsuredExtensionDataModel = {
	/** 是否是主标的 */
	mainInsured?: 'Y' | 'N';
	/** 是否是法定受益人. N:法定; Y:指定 */
	hasDesignatedBeneficiary?: 'Y' | 'N' | string;
	/** 是否是法定受益人. Y:是; N: 否*/
	legalIndi?: 'Y' | 'N';
	/** 显示顺序 */
	displayOrder?: number;
	/**index */
	insuredIndex?: number;
	[propName: string]: any;
}
export type InsuredModel = {
	/** 标的序列号, 内部使用.如果是第三方输入数据, 该字段无需填写. */
	insuredId?: string | number;
	/** 标的名称, 可以是人名, 牌照等等 */
	insuredName?: string;
	/** 标的类型. 参考标的类型 */
	insuredType?: InsuredType;
	/** 标的关键号码. 例如身份证号码, 车牌号码等 */
	keyNo?: string;
	/** 标的费用. 参考标的费用模型 */
	insuredFee?: InsuredFeeModel;
	/** 被保人, 受益人, 或其他保单相关方. 参考被保人, 受益人以及利益相关方说明 */
	participants?: (InsuredParticipant | BeneficiaryParticipant | GuardianParticipant)[];
	/** 险种. 参考险种模型 */
	coverages?: CoverageModel[];
	/** 标的扩展字段 */
	extensionData?: InsuredExtensionDataModel;

};

// export enum CLPCPPolicyStatus {
// 	WAIT_FOR_SUBMIT = '11',
// 	QUOTE_SUBMITTED = '51',
// 	AUTO_UW_PASS = '106',
// }

export enum PolicyDetailStatus {
	DELETE_CATEGORY = "0",// "删除"),
	DELETE = "1", //"删除", "0"),
	WAIT_FOR_SUBMIT_CATEGORY = "10", //"待提交"),
	WAIT_FOR_SUBMIT = "11", //"待提交", "10"),
	SUBMITTED_CATEGORY = "50", //"投保提交"),
	SUBMITTED = "51", //"投保提交", "100"),
	SUBMITTED_FAILED = "52", //"投保提交失败"),
	QUOTED_CATEGORY = "100", //"承保中"),
	QUOTE_MANUAL_UW = "101", //"人工核保中", "100"),
	QUOTE_FAILED = "102", //"投保失败", "100"),
	QUOTE_CANCEL = "103", //"撤单", "100"),
	WAITING_FOR_COLLECTION = "114", //"待支付"),
	QUOTE_TRANSFERRING = "104", //"转账收费中", "100"),
	QUOTE_TRANSFER_FAILED = "105",// "转账失败", "100"),
	QUOTE_AUTO_UW_PASS = "106", //"自核通过", "100"),
	QUOTE_UW_PASS = "107", //"核保通过", "100"),
	QUOTE_TRANSFER_SUCCESS = "108",// "转账成功", "100"),
	QUOTE_OTHER = "109", //"其他", "100"),
	QUOTE_UW_1 = "110", //"核保中", "100"),
	QUOTE_UW_2 = "111", //"核保中", "100"),
	QUOTE_AUTO_UW_FAILED = "112", //"自核失败", "100"),
	QUOTE_REFUNDING = "120",// "退费中", "100"),
	QUOTE_REFUND_SUCCESS = "121",// "退费成功", "100"),
	QUOTE_REFUND_FAILED = "122", //"退费失败", "100"),
	COLLECTION_APPLY_PENDING = "123",// "支付申请中", "100"),
	ISSUED_CATEGORY = "200", //"承保"),
	ISSUED = "201", //"已承保", "200"),
	ISSUED_RECEIPT_RETURNED = "202",// "已回执", "200"),
	ISSUED_RECEIPT_AUDITING = "203", //"回执审核中", "200"),
	ISSUED_RECEIPT_DONE = "204", //"已回访", "200");
	EXIT_THE_INSURED = "504",//已退保
}

export enum PolicyTempStatus {
	QUOTE_MANUAL_UW = "101", // 人工核保中, submitPolicy/policyId
	QUOTE_MANUAL_UW_2 = "115", //
	QUOTE_MANUAL_UW_3 = "112", //lian返回的人核/核保失败状态
	QUOTE_AUTO_UW_PASS = "106", // 自核通过, 调用保司影像上传（wanxiang），调收银台（李琦）
	QUOTE_UW_1 = '110',// 核保中，进入反洗钱（tim.zhou）
	// lian判断用到
	uwResultFlag = "1", // 投保失败
	uwResultFlag_2 = "2", //  人核
}

/**
 *  1现金
	2支票
	3银行转账
	4信用卡
	5微信支付
	6线下转账
	7银联网页支付
	999其他
 */
export enum PaymentMethod {
	BATCH_OFFLINE = '6',
	REAL_TIME = '3',
	CASH = '1',
	CHEQUE = '2',
	CREDIT_CARD = '4',
	WECHAT_PAY = '5',
	UNION_WEB_PAY = '7',
	CASHIER_DESK_WEB_PAY = "8",//"收银台网页支付"),
	OTHER = '999'
}

export enum PayType {
	REAL_TIME_DEDUCTION = '1',//实时扣款
	BANK_APPROVED_DEDUCTION = '2'//银行批扣
}

/**
 * 签名方式
 */
export enum SignatureMethod {
	LOCAL = '1',
	SHARE = '2'
}

export enum ChangeBankStatus {
	SAVED = '1',//暂存
	MODIFY = '2'
}

export enum AgentDocumentType {
	/** 保险经纪服务委托合同签名 */
	CONSIGNMENT_AGREEMENT_SIGNATURE = '-134',
	CONSIGNMENT_AGREEMENT_SIGNATURE_IMAGE = '-135',
	/** 代理人签名 */
	AGENT_SIGNATURE = '-2',
	/**人身保险投保提示书*/
	INSURANCE_NOTICE_SIGNATURE = '-139'
}

export type CaPositionKeywordModel = {
	keyword: string;
	offsetX: number;
	offsetY: number;
	page: number;
	times: number;
}

export type CaPositionModel = {
	height: number;
	keyword: CaPositionKeywordModel,
	page: number;
	positionType: string;
	width: number;
}

export type CaCommandModel = {
	imageUrl?: string;//传oss地址
	base64Image: string;
	email: string;
	idNo: string;
	idNoType: string;
	mobile: string;
	positions: CaPositionModel[],
	realName: string;
}

export type AgentDocument = {
	/** 文档类型, 参考文档类型 */
	documentType:
	| AgentDocumentType.CONSIGNMENT_AGREEMENT_SIGNATURE
	| AgentDocumentType.CONSIGNMENT_AGREEMENT_SIGNATURE_IMAGE
	| AgentDocumentType.INSURANCE_NOTICE_SIGNATURE
	| AgentDocumentType.AGENT_SIGNATURE;
	/** 文档URL */
	url?: string;
	/** 险种(保项)的代码 */
	coverageCode?: string;
	/** 文档URI */
	uri?: string;
	/** 是否为正面，如果是，则true; 否则false*/
	front?: boolean;
	/** 文档上传至保司的地址 */
	insurerUrl?: string;
	/** 上传时间 */
	uploadTime?: string;
} & SignatureDocument;

export enum BankCardType {
	DEBIT_CARD = "1", // "借记卡"),
	BANKBOOK = "2", // "存折"),
	CREDIT_CARD = "3",// "信用卡"),
	WECHAT = "4",// "微信支付"),
	OTHER = "9", //"不明");
}

export enum PaymentStatus {
	FAIL = "0", //"支付失败"),
	SUCCESS = "1",// "支付成功"),
	IN_PROGRESS = "2",// "支付中");
}

export enum ReceiptType {
	SIGNED = "0", // "普票"),
	UNSIGNED = "1", // "专票"),
	CANCELLED = "2", //"电子发票");
}

export type PaymentInfo = {
	/**付款方式 */
	paymentMethod?: PaymentMethod | string | number;
	/** 银行code */
	bankCode?: string;
	/** 银行卡号 */
	bankCard?: string;
	/** 开户省code*/
	bankAccountProvinceCode?: string;
	/**开户省中文名 */
	bankAccountProvince?: string;
	/** 开户市code*/
	bankAccountCityCode?: string;
	/** 开户市中文名*/
	bankAccountCity?: string;
	/**银行卡类型 */
	bankCardType?: BankCardType;
	/**银行预留手机号 */
	mobile?: string;
	/**银行名字 */
	bankName?: string;
	/**支付失败原因 */
	paymentMsg?: string;
	/** 交易流水号,非页面输入，在调用支付接口后，自动设置 */
	paymentTransNo?: string;
	/**支付日期 */
	paymentDate?: string;
	/**支付状态 */
	paymentStatus?: PaymentStatus | string | number;
	/**扣款方式 */
	payType?: PayType | string;
	/**发票类型 */
	receiptType?: ReceiptType;
	/**纳税人识别号 */
	taxpayerCode?: string;
}

export type NextPaymentInfo = {
	/**
	 * @deprecated never used
	 * 续期银行
	 */
	bankName?: string;
	/** 扣款方式 */
	payType?: PayType | string;
	/** 续期帐号户名 */
	nextHolderName?: string;
	/** 续期银行卡类型 */
	nextBankCardType?: BankCardType | string;
	/** 续期银行 */
	nextBankName?: string;
	/** 续期银行code */
	nextBankCode?: string;
	/** 续期银行卡号 */
	nextBankCard?: string;
	/** 续期开户省code*/
	bankAccountProvinceCode?: string;
	/** 续期开户省中文名 */
	bankAccountProvince?: string;
	/**  续期开户市code*/
	bankAccountCityCode?: string;
	/**  续期开户市中文名*/
	bankAccountCity?: string;
}

export enum PolicyStatus {
	QUOTE = 1,
	ISSUED = 2,
	WAIT_FOR_PAYMENT = 3,
	ADVISE = 5,
	DELETED_ADVISE = 6,
	PAID = 7,
	ISSUING = 8,
	DELETED_QUOTE = 9,
	INSURE_FAILED = 10,
	WAIT_FOR_SUBMIT = 11,
	PAY_FAILED = 87,
	INVALID = 88,
	WITHDRAWN = 96,
	DECLINE = 97,
	CANCELLED = 98,
	TERMINATED = 99
}

export enum PolicyType {
	PERSONAL = "1",// "个单"),
	GROUP = "2",// "团单");
}

export enum CommissionType {
	FROM_VENDOR = "1",// "From Vendor"), //
	TO_DIRECT_AGENT = "2",// "To Direct Agent"), //
	TO_HIERARCHICAL_AGENT = "3",// "To Hierarchical Agent");
}

type FeeAmountModel = {
	/** 费用明细序列号, 内部使用.目前该字段无需填写. */
	feeItemId: number;
	/** 费用代码*/
	code: string;
	/** 费用名称 */
	name: string;
	/** 费用金额 */
	amount: number;
}

type FeeModel = {
	/**实付保费 */
	app?: number | string;
	/**费用序列号, 内部使用.目前该字段无需填写. */
	feeId?: number;
	/**毛保费 */
	sgp?: number;
	/** 调整后毛保费 */
	agp?: number
	/**净保费 */
	snp?: number;
	/** 调整后净保费 */
	anp?: number;
	/** 费用明细清单. 参考费用明细 */
	feeAmounts?: FeeAmountModel[]
}

export type PolicyFeeModel = FeeModel & {}

export type InsuredFeeModel = FeeModel & {}

export type CoverageFeeModel = FeeModel & {}

export type LiabilityFeeModel = FeeModel & {}

export type PolicyCommossionModel = {
	/** 佣金序列号, 内部使用.如果是第三方输入数据, 该字段无需填写.*/
	commissionId?: number;
	/** 佣金类型. 参考佣金类型 */
	type?: CommissionType;
	/** 佣金金额 */
	amount?: number;
	/** 发放佣金的租户代码 */
	fromTenantCode?: string;
	/** 发放佣金的机构代码, 可以为空.如果不为空, 并且fromTenantCode为空, 则自动填写fromTenantCode*/
	fromOrganCode?: string;
	/** 接受佣金的租户代码 */
	toTenantCode?: string;
	/** 接受佣金的机构代码, 可以为空.如果不为空, 并且toTenantCode为空, 则自动填写toTenantCode*/
	toOrganCode?: string;
	/** 接受佣金的账号名称, 可以为空.如果不为空, 并且系在系统中开户的账号, 则自动填写toTenantCode, toOrganCode与toAccountId, 无论此三栏位是否有值.*/
	toAccountName?: string;
	/** 接受佣金的账号序列号, 可以为空.如果是第三方输入数据, 该字段无需填写.*/
	toAccountId?: number;
	/** 分期序列号, 默认为1*/
	installment?: number;
	/** 佣金结算日期 */
	settleDate?: string;
	/** 保单序列号, 关联用. 内部使用如果是第三方输入数据, 该字段无需填写.*/
	policyId?: number;
	productCode?: string;
	productVersion?: string;
	vendorCode?: string;
	quotationNo?: string;
	policyNo?: string;
	insureds?: InsuredModel[];
	policyHolder?: Participant & {
		extensionData?: {
			receiptSignature?: { signatureId?: number | string };
			status?: string;
		};
	};
	sourceType?: SourceType | string;
	agent?: {
		tenantCode?: string;
		organizationCode?: string;
	};
}

export type PolicyCommissionsModel = {
	/** 产品供应商发放的第一期佣金金额 */
	fromVendor?: number;
	/** 发放给直接代理人的第一期佣金金额. 如果是保险公司直接业务, 应该与fromVendor相等.*/
	toDirectAgent?: number;
	/** 佣金明细清单. 参考佣金明细*/
	commissions?: PolicyCommossionModel[];
}

export type UWResult = {
	/** 核保结果代码 */
	resultCode?: string;
	/** 核保不通过时的原因 */
	reasons?: string | string[];
}

export type History = {
	/** 保单状态,参考详细保单状态说明 */
	status?: string;
	/** 保单处理时间 */
	statusDate?: string;
	/** 保单处理信息日志 */
	message?: string;
}

export type ShortMessage = {
	/** 手机号码 */
	phone?: string;
	/** 短信内容 */
	content?: string;
}

export type Step = {
	/** 页面步骤Code 非空,参考详细步骤轨迹说明*/
	stepCode?: string;
	/** 	步骤时间，非空*/
	stepDate?: string;
	/** 具体说明 */
	message?: string;
}

export type Issue = {
	/** 文档类型, 参考文档类型 */
	documentType?: string;
	/** 回答内容 */
	feedback?: string;
	/** 问题件回答提交保司日期 */
	feedbackDate?: string;
	/** 问题件状态, 参考问题件状态 */
	issueStatus?: string;
	/** 问题件类型（保司）, 参考问卷类型 */
	issueType?: string;
	/** 角色类型, 参考角色类型 */
	roleType?: number;
	/** 问题件内容集合, 参考问题件内容集合*/
	questionData?: string;
	/** 插入时间 */
	insertTime?: string;
	/** 修改时间 */
	updateTime?: string;
	/** 当前步骤 */
	step?: number | string;
	/** 批次 */
	sequence?: string | number;
	/** 扩展栏位, 存放一切未显式定义的内容, 参考问题件扩展栏位*/
	extensionData?: string;
}

export type PolicyExtensionDataModel = {
	/** 保单级告知, 参考保单级告知模型 */
	declarations?: ParticipantDeclaration[];
	/** 保单级文档, 参考保单级文档模型,以数组模型为准 */
	documents?: object | AgentDocument[];
	/** 短信，参考短信模型*/
	shotMessages?: ShortMessage[];
	/** 核保结果，参考核保结果模型 */
	uwResults?: UWResult[];
	/** 支付信息，参考支付信息模型 */
	paymentInfo?: PaymentInfo;
	/** 建议书留言 */
	advice?: string;
	/** 是否显示建议书留言（Y : N）*/
	showAdvice?: string;
	/** 1,2,3  身份验证类型*/
	verificationType?: string | number;
	/** true,false 验证结果 */
	verificationResult?: boolean;
	/** 是否csms开发的产品页面，默认值为UI */
	csms?: string;
	/** 第三方接口调用投保流程页面完成后，返回第三方页面时的路径*/
	thirdPartyReturnUrl?: string;
	/** 支付超时时间戳 */
	expirationTime?: string;
	/** 续期支付信息(北京人寿B2C添加,B2A不需要)，参考续期支付信息模型 */
	nextPaymentInfo?: NextPaymentInfo;
	/** 当前投保步骤键值 */
	step?: string;
	/** 保单状态（详细）. 参考详细保单状态说明 */
	status?: PolicyDetailStatus;
	/** 对于定额定费产品单位被保人保费字段 */
	unitPremium?: string;
	/** 对于一款产品多种计划，指计划名 */
	planCode?: string;
	/** 事务序列号。（对于有些保险公司，它就只是一个电投流程的事务序列号；对于有些保险公司，它不但是投保流程的事务序列号，也是投保单号，因为对于这类保险公司，它们都是能唯一标识一个投保流程的。） */
	transactionNo?: string;
	/** 纸质投保单号（若有）*/
	printNo?: string;
	/** 保单说明（目前瑞华影像需要） */
	comments?: string;
	/** 免登陆扩展参数（可不传），用于租户自定义传值，如传特殊字符，需base64编码处理传值.易保云通知接口回调时，会在policy对象扩展字段中parcel返回*/
	parcel?: string;
	/** 是否自动垫交(Y : N) */
	autoLoan?: string;
	/** 保单历史轨迹,参考保单历史轨迹 */
	historyList?: History[];
	/** 页面步骤历史轨迹,参考页面步骤历史轨迹*/
	stepList?: Step[];
	/** 计算利益演示时费率档次值 */
	interestRate?: number | string;
	/** 保单扩展字段是否双录字段 (true | false)*/
	needDoubleRecording?: boolean | string;
	/** 保单级问题件, 参考问题件模型 */
	issues?: Issue[];
	/** 是否有待处理问题件，Y/N */
	hasIssue?: string;
	/** 根供应商 */
	rootVendorCode?: string;
	/** 签名方式,参考签名方式 */
	signatureMethod?: SignatureMethod | string;
	/** 保单人员种类,1-个人单,2-家庭单 */
	policyPersonKind?: string;
	/** 是否自保/互保件 */
	selfInsurance?: string;
	/** 溢交保费方式，1-退费，2-抵交续期保费 */
	outPayFlag?: string;
	/** 联系人姓名 */
	contactName?: string;
	/** 联系人电话 */
	contactPhone?: string;
	/** 联系人证件号码 */
	contactIdNo?: string;
	/** 联系人证件起期 */
	contactIdValidStart?: string;
	/** 联系人证件止期 */
	contactIdValidTill?: string;
	/** 联系人证件是否为长期 */
	contactIdNoLongEffFlag?: boolean;
	/** 联系人国籍 */
	contactNationality?: string;
	/** 法人姓名 */
	legalPersonName?: string;
	/** 法人证件类型 */
	legalPersonIdType?: string;
	/** 法人号码 */
	legalPersonIdNo?: string;
	/** 企业类型编码 */
	companyNature?: string;
	/** 投保人数 */
	insuredPeoples?: number | string;
	/** 行业性质名称 */
	businessBigType?: string;
	/** 保司产品代码 */
	externalCode?: string;

	/**保单临时状态 */
	tempStatus?: PolicyTempStatus;
	/** 外部进入投保流程链接 */
	rootUrlFrom?: string;
	/** 是否有豁免险校验（Y	: N） */
	hasExemptionInsurance?: string;
	/** 产品详情页进入投保流程地址 */
	urlFrom?: string;
	/** 当前正在签名的序列号 */
	signatureId?: string | number;
	/** 验证码 */
	validateCode?: string;
	/** 回执方式 */
	receiptMethod?: ReceiptMethod;
	/** IMPORTANT 临时图片, 用于显示某张图片用, 不持久化, 用完需要被删掉 */
	tempImage?: string;
	/** IMPORTANT 临时年龄, 用于显示文案信息判断, 不持久化, 用完需要被删掉 */
	tempAge?: string;
	/**保全模型 */
	csModel?: CSModel;
	/**保全action */
	csAction?: CsAction | string;
	/**临时 */
	tempInsuredId?: number;
	/**临时 */
	tempInsuredParticipantId?: number;
	/**临时 */
	tempCoverageId?: number;
	//微信小程序openid
	openid?: string;
	/** 可回溯所有步骤 */
	gluttonAllSteps?: string[];
	/**代理人在保司的code */
	companyAgentCode?: string;
	/**代理人在保司的名字 */
	companyAgentName?: string;
	/** 公司信息 */
	companyName?: string;
	/** 公司code */
	companyCode?: string;
	/** 代理人销售编码（单证初始化数据需用到） */
	agentSalesCode?: string;
	/** 需要阅读的单证step步骤 */
	noticeReadSteps?: noticeReadSteps[];
	receipt?: string;
	[propName: string]: any;
}

export type PolicyHolderModel = Participant & {
	extensionData?: {
		/** 回执分享签字用到状态 */
		receiptSignature?: {
			signatureId?: number | string
		};
	};
}
export type PolicyAgentModel = {
	/** 租户代码此栏位无需填写 */
	tenantCode?: string;
	/** 机构代码此栏位无需填写 */
	organizationCode?: string;
	/** 账户名称.此栏位必须填写, 同时自动根据此栏位填写tenantCode, organizationCode和accountId */
	accountName?: string;
	/** 账户序列号, 内部使用.此栏位无需填写*/
	accountId?: number;
}


export type PolicyModel = {
	/** 保单模型初始化内使用 */
	_init?: any;
	/** 保单序列号, 内部使用.如果是第三方输入数据, 该字段无需填写. */
	policyId?: number;
	/** 产品序列号, 内部使用.如果是第三方输入数据, 该字段无需填写. */
	proudctId?: number;
	/** 产品提供商代码, 必须提供.对照ProductVendor */
	vendorCode?: string;
	/** 产品代码, 必须提供.对照Product */
	productCode?: string;
	/** 产品版本, 必须提供.对照Product */
	productVersion?: string;
	/** 保单生效日 */
	effectiveDate?: string;
	/** 保单失效日 */
	expiryDate?: string;
	/** 订单号, 内部使用，易保订单号.调用update API时, 使用本栏位精确匹配. 若未匹配到, 则报错QUOTE_NOT_EXISTS.调用create API时, 使用本栏位精确匹配. 若匹配到, 则报错QUOTE_EXISTS */
	quotationNo?: string;
	/** 保单号. */
	policyNo?: string;
	/** 出单日 */
	issueDate?: string;
	/** 首次报价日 */
	quoteDate?: string;
	/** 第三方(例如：保司)报价单号 */
	thirdQuoteNo?: string;
	/** 第三方(例如：保司)订单号 */
	thirdOrderNo?: string;
	/** 电子保单下载地址 */
	ePolicyDownloadUrl?: string;
	/** 保费.无需填写此栏位. 写入数据前使用JPA Listener自动从PolicyFee.app中复制过去. */
	premium?: string | number;
	/** 是否是续保 */
	renewal?: boolean;
	/** 上一张保单的保单号, 只有在续保件的时候才会有值, 并且必须有值. */
	lastPolicyNo?: string;
	/** 保单状态. 参考保单状态说明 */
	policyStauts?: PolicyStatus;
	/** 保单层级费用. 参考保单费用说明 */
	policyFee?: PolicyFeeModel;
	/** 保单级佣金. 参考保单佣金说明object类型 */
	commissions?: PolicyCommissionsModel;
	/** 扩展栏位, 存放一切未显式定义的内容. 参考保单扩展栏位说明 */
	extensionData?: PolicyExtensionDataModel;
	/** 标的. 例如寿险的被保人, 车险的车辆等. 参考标的说明 */
	insureds?: InsuredModel[];
	/** 投保人. 参考投保人说明 */
	policyHolder?: PolicyHolderModel;
	/** 代理人. 参考代理人说明 */
	agent?: PolicyAgentModel;
	/** 第三方代理人号,对于第三方的只做保存，用于报表显示.*/
	thirdPartyAgentId?: string;
	/** 第三方代理人姓名,对于第三方的只做保存，用于报表显示. */
	thirdPartyAgentName?: string;
	/** 保单渠道 */
	channel?: string;
	/** 投保日期*/
	applyDate?: string;
	/** 回执日期 */
	acknowledgeDate?: string;
	/** 回访日期 */
	callbackDate?: string;
	/** 保单类型 参考保单类型 */
	policyType?: PolicyType;
	/** 保单细状态 114为待支付  100为承保中  10为待提交 (目前主模型有，前端是在extenDate中取的，待优化)*/
	detailstatus?: PolicyDetailStatus;
	/** 保单来源类型 */
	sourceType?: SourceType | string | number;
	[propName: string]: any;
};



//回执方式
export enum ReceiptMethod {
	LOCAL = '1',
	SHARE = '2'
}

export enum FaceRecongnizeRoleType {
	POLICY_HOLDER = 'PROPOSER',
	POLICY_INSURED = 'INSURED',
	POLICY_BENEFICIARY = 'BENEFICIARY'
}

// 保单模型 ********************************************


export enum CsAction {
	//变更银行卡信息
	CHANGE_BANK_INFO = 'changeBankInfo',
}

//保全时的模型
export type CSModel = {
	documents?: (
		| PolicyDocument
		| ParticipantSignatureDocument
		| ParticipantSignatureCADocument)[];
	[propName: string]: any;
}

export enum PolicyStepName {
	STEP_INTRO = 'intro',//产品详情
	STEP_ADVICE_PARTICIPANTS = 'advice-participants',//计划书投被保人信息录入
	STEP_ADVICE_COVEAGES = 'advice-coverages',//计划书制定
	STEP_ADVICE = 'advice',//计划书
	STEP_NOTICE = 'notice',//投保须知
	STEP_HEALTH_NOTICE = 'health-notice',//健康告知
	STEP_OUTLINE_PARTICIPANTS = 'outline-participants',//投被保人信息录入
	STEP_PARTICIPANTS = 'participants',//投被保人信息录入
	STEP_COVEAGES = 'coverages',//计划制定
	STEP_HOLDER_FILL = 'holder-fill',//投保人信息录入
	STEP_PERSON_INFO = 'personInfo',//投保人信息录入,复星用
	STEP_INSUREDS_FILL = 'insureds-fill',//被保人信息录入
	STEP_DATA_ENTRY = 'data-entry',//投被保人受益人信息录入
	STEP_HOLDER_DECLARATIONS = 'holder-declarations',//投保人告知
	STEP_INSURED_DECLARATIONS = 'insured-declarations',//被保险人告知
	STEP_AGENT_DECLARATIONS = 'agent-declarations',//代理人告知
	STEP_IMAGE_UPLOAD = 'image-upload', //影像上传
	STEP_IMAGE_UPLOAD_RETRY = 'image-upload-retry', //影像上传重试
	STEP_RENEWAL_PAYMENT = 'renewal-payment',//续期缴费信息
	GENERATION_CHECK_VIEW = 'generation-check-view',//'影像审核页',
	STEP_AUTHORIZATION_AND_NOTICE = 'authorization-and-notice',//授权与须知
	STEP_REVIEW_ORDER = 'review-order',//核对订单
	STEP_SIGN_REVIEW_ORDER = 'sign-review-order',//分享核对订单
    STEP_SIGNATURE = 'signature',
	STEP_APPLY_CONFIRM = 'apply-confirm',// 核对订单,1an
	STEP_PAYMENT = 'payment',//支付保费
	STEP_PAYMENT_REMINDER = 'payment_reminder',//交费提示
	STEP_UNDERWRITE_FAILED = 'underwrite-failed',//核保失败
	STEP_APPLY_FAILED = 'apply-failed',//投保失败
	IMAGE_UPLOAD_FAILED = 'image-upload-failed',//投保失败
	STEP_ISSUE_FAILED = 'issue-failed',//承保失败
	STEP_PAY_FAILED = 'pay-failed',//支付失败
	STEP_PAY_SUBMIT_SUCCESS = 'pay-submit-success',//支付成功
	STEP_ISSUE_SUCCESS = 'issue-success',//承保成功
	STEP_POLICY_INFO = 'policy-info',//订单详情
	STEP_ORDER_DETAILS = 'order-details',//订单详情
	STEP_POLICY_VIEW = 'policy-view',//保单详情
	STEP_APPLY_PEDDING = 'apply-pedding',//投保轮训
	STEP_SMS_VERIFICATION = 'sms-verification',//短信验证
	STEP_ELECTRONIC_CONFIRM_FORM = 'electronic-confirm-form',//电子投保确认函
	STEP_SIGN_SIGNATURE = 'sign-signature',//签字
	CONFIRM_INSURED_IDENTITY = 'confirm-insured-indentity',//被保人信息
	SMS_SUCCESS = 'sms-success',//被保人验证成功
	STEP_QUESTIONS = 'questions',//详情页产品问答
	STEP_EPOLICY_VIEW = 'epolicy-view',
	STEP_ELETTER_VIEW = 'eletter-view',//电子保函查看
	STEP_POLICY_SURRENDER = 'policy-surrender', //保单退保
	STEP_SURRENDER_SUCCESS = 'surrender-issue', //退保成功
	STEP_SURRENDER_FAIL = 'surrender-fail',//退保失败
	STEP_SURRENDER_CALCULATE = 'surrender-calculate',//退保试算
	STEP_SURRENDER_CONFIRM = 'surrender-confirm',//退保确认
	STEP_SURRENDER_INFO= 'surrender-info',//退保结果
	PREFIEX_STEP_RATING = 'rating-',//费率表
	PREFIEX_STEP_BANK_TRANSFER_AUTHORIZATION = 'bank-transfer-authorization-',//银行转账授权声明
	PREFIEX_STEP_CUSTOMER_NOTICE_REAL = 'customer-real-',//客户真实性提示
	PREFIEX_STEP_CLAIM_APPLICATION = 'claim-application-',//理赔申请书
	PREFIEX_STEP_MATERIAL_COLLECTION_GUIDE = 'material-collection-guide-',//材料收集指南
	PREFIEX_STEP_PROTECTION_DETAILS = 'protection-details-',//保障详情
	PREFIEX_STEP_POLICY_SAMPLE = 'policy-sample-',//保单样本
	PREFIEX_STEP_AFTER_SALES_NOTICE = 'after-sales-notice-',//售后须知
	PREFIEX_STEP_VALUE_ADDED_SERVICE = 'value-added-service-',//增值服务
	PREFIEX_STEP_OCCUPATION_LIST = 'occupation-list-',//职业列表
	PREFIEX_STEP_HEALTH_SERVICE = 'health-service-',//健康服务
	PREFIEX_STEP_CUSTOMER_NOTICE_EQUITY = 'customer-equity-',//“忠诚客户”权益
	PREFIEX_STEP_CUSTOMER_NOTICE_SERVICE_GUIDE = 'customer-service-guide-',//客户服务指南
	PREFIEX_STEP_STATEMENT_AND_CONFIRMATION = 'statement-and-confirmation-',//声明及确认事项书
	PREFIEX_STEP_STATEMENT = 'statement-',//声明
	PREFIEX_STEP_WITHHOLD_NOTICE = 'withhold-notice-',//扣款知情同意书
	PREFIEX_STEP_HEALTH_GUARD_SERVICES = 'health-guard-services-',//e就医健康卫士尊享服务
	PREFIEX_STEP_CLAUSE = 'clause-',//条款
	PREFIEX_STEP_RECEIPT = 'receipt-',//条款
	PREFIEX_APPLY_NOTICE = 'apply-notice-',//详细投保须知
	PREFIEX_APPLY_PAGE = 'terms-coverage-',//险种条款
	PREFIEX_STEP_CUSTOMER_NOTICE_NOTICE = 'customer-notice-',//客户告知书
	PREFIEX_STEP_INFO_DISCLOSURE = 'info-disclosure-',//产品信息披露
	PREFIEX_STEP_PRODUCT_SPECIFICATION = 'product-specification-',//产品说明书
	PREFIEX_STEP_INSURANCE_BROKER = 'insurance-broker-',//保险经纪服务委托合同
	PREFIEX_STEP_INSURANCE_NOTICE = 'insurance-notice-',//投保提示书
	PREFIEX_STEP_QUOTE_REMINDER = 'quote-reminder-',//产品投保提示书
	PREFIEX_STEP_QUOTE_RULES = 'quote-rules-',//产品投保规则
	PREFIEX_STEP_REMOVE_LIAB = 'remove-liab-',//产品免责声明
	PREFIEX_STEP_ENABLING_CLAUSE = 'enabling-clause-',//授权条款
	PREFIEX_STEP_TRANSFER_AUTHORIZATION = 'bank-transfer-',//银行转账授权书
	PREFIEX_STEP_RISK_HINT = 'risk-hint-',//风险提示
	PREFIEX_STEP_APPLY_ISSUE_SERVICE_GUIDE = 'apply-issue-service-guide-',//投保及承保服务指南
	PREFIEX_STEP_CS_SERVICE_GUIDE = 'cs-service-guide-',//保全服务指南
	PREFIEX_STEP_CLAIM_SERVICE_GUIDE = 'claim-service-guide-',//理赔服务指南
	PREFIEX_STEP_COMMON_PROBLEM = 'common-problem-',//常见问题
	PREFIEX_STEP_PRIVACY_POLICY = 'privacy-policy-',//隐私政策
	PREFIEX_STEP_CASH_VALUE = 'cash-value-',//现金价值表
	PREFIEX_STEP_WITHHOLDING_FEE_BCM = 'withholding-fee-bcm-',//银行代扣费协议-BCM 交通银行
	PREFIEX_STEP_WITHHOLDING_FEE_PSBC = 'withholding-fee-psbc-',//银行代扣费协议-PSBC 中国邮政
	PREFIEX_STEP_INFORMATION_RECIPIENTS_LIST = 'information-recipients-list-',//个人信息接收方清单
	PREFIEX_STEP_PURCHASE_INFORMATION = 'purchase-information-',//购买须知
	PREFIEX_STEP_RISK_QUESTION = 'risk-question-',//风险问卷
	PREFIEX_STEP_PRODUCT_STATEMENT = 'product-statement-',//产品说明书
	PREFIEX_STEP_METRIAL = 'metrial-',//资料

	PREFIEX_STEP_WHITE_LIST = 'white-list-',//白名单
	PREFIEX_STEP_INSURANCE_LIABILITY = 'insurance-liability-',//保险责任
	PREFIEX_STEP_MEDICAL_INSTITUTION = 'medical-institution',
	PREFIEX_STEP_AGREEMENT = 'AGREEMENT-',//委托协议
	PREFIEX_STEP_CUSTOMER = 'CUSTOMER-',//客户告知书
	PREFIEX_STEP_CUSTOMER_REAL = 'customer-real-',//客户真实性提示
    CASH_VALUE = 'cash-value',//现价表

	PREFIEX_STEP_SPECIAL_AGREEMENT = 'special-agreement-',//特别约定
    PREFIEX_STEP_IMPORTANT_NOTICE = 'important-notice-',//重要通知
    PREFIEX_STEP_QUOTATIONI_NOTICE = 'quotation-notice-',//投保须知
    PREFIEX_STEP_JOB_CATEGORY = 'job-category-',//职业类别
    PREFIEX_STEP_CLAUSE_SERVICES = 'clause-services-',//保单服务
    PREFIEX_STEP_SPECIAL_TUMORS_DRUGS = 'special-tumors-drugs-',//恶性肿瘤特定药品目录
    PREFIEX_STEP_HEALTH_MANAGEMENT_MANUAL = 'health-management-manual-',//健康管理服务手册
    PREFIEX_STEP_CLAIM_NOTICE = 'claim-notice-',//理赔流程
    PREFIEX_STEP_LIABILITY_EXEMPTION_CLAUSE = 'liability-exemption-clause-',//除外责任
    PREFIEX_STEP_HEALTH_NOTICE = 'health-notice-',//健康告知
	PREFIEX_STEP_CUSTOMER_NOTICE = 'customer-notice-',//客户告知书
	PREFIEX_STEP_SCHEDULE = 'scheduleOfRates-',//费率表
	EPOLICY_VIEW = 'epolicy-view', // 查看电子保单
	CUS_INFO_AUTHORIZE = 'cus-info-authorize',//个人信息处理知情及授权
    PREFIEX_STEP_OTHER_ILLUSTRATE= 'other-illustrate-',//其他影响保单效力说明
	PREFIEX_STEP_FACE_RECOGNIZE_AGREEMENT = 'face-recognize-agreement-',//人脸识别服务协议

}

export enum ChannelName {
	DEFAULT_APP = "2A",// "E保电投"),
	// E保网销商城
	ESHOP = "eShop-2A", //"E保网销商城"),
	// 江苏有线
	JSCN = "JSCN",// "江苏有线");
	//永保
	WECHAT = "WeChat-2A",// "永保");
	//官网
	OFFICIALWEB = "OfficialWeb",// "官网");
}


/**
 * 保单来源类型
 */
export enum SourceType {
	DEFAULT = '0', //默认
	OFFLINE = '1', //线下单
	MALL = '3', //商城
	MALL_2C = '4' //商城2客户
}

export enum PaymentAuthStatus {
	SIGNED = "0",//已签约
	UNSIGNED = "1", //未签约
	CANCELLED = "2",//已解约
	UN_NEED_SIGNED = "3"//"无需签约");
}

export enum MallProductTags {
	A1 = 'A1',//寿险
	A2 = 'A2',//健康险
	A3 = 'A3',//意外险
	A4 = 'A4',//责任险
	A5 = 'A5',//财产险
	A6 = 'A6',//重疾险
	A7 = 'A7',//医疗险
	A8 = 'A8',//年金险
}

export enum QuoteUrlKeys {
	NOT_DEFINED = ':nil',
	CURRENT_URL = ':currentUrl',
	CURRENT_THEME = ':currentTheme',
	PARCEL = ':parcel',
	OPENID = ':openid',
	FUNC_TYPE = ':functionType',
	RETURN_URL = ':returnUrl',
}

export enum ProductSupportType {
	UI_NOT_SUPPORTED = 0,
	EXTERNAL_UI = 1,
	MYSELF = 2
}

export enum QuoteFailureStatus {
	APPLY_FAIL = '1',
	ISSUE_FAIL = '2',
	PAY_FAIL = '3',
	UW_FAIL = '4',
	DOCUMENT_UPLOAD_FAIL = '5',
	CHANKBANK_FAIL = '6'
}

/** 页面标记位 */
export enum PolicyPageFlagKey {
	BACK = 'BACK',
	QUIT = 'QUIT',
	FAV = 'FAV',
	SHARE = 'SHARE',
	STEP_ON_POLICY = 'STEP_ON_POLICY',
	ADVICE_PERMITTED = 'ADVICE_PERMITTED',
	QUOTE_PERMITTED = 'QUOTE_PERMITTED',
	VIEW_PERMITTED = 'VIEW_PERMITTED',
	READ_ONLY = 'READ_ONLY'
}

export enum UIType {
	INFO = 'INFO',
	QUOTE = 'QUO',
	ADVISE = 'ADV',
	SIGNATURE = 'SIGN',
	VIEW = 'VIEW'
}

export enum SalesmanType {
	AGENT = "1",// "代理人"),
	FINANCIAL_PLANNER = "2", // "理财师"),
	FPC = "3",// "FPC"),
}

export type noticeReadSteps = {
	stepName: string;
	readComplete: boolean;
}

export type UICriteira = {
	productCode: string;
	productVersion: string;
	// 取实际产品的rootVendorCode找UI
	vendorCode: string;
	tenantCode: string;
	uiType: UIType;
	salesType?: string;
};
export type ProductUIInitAction = {
	do: ActionFunctionNames | ActionCustomFunc;
	params: any;
};
export type ProductUIDataModel = {
	_init?: {
		actions: ProductUIInitAction[];
	};
};
export type ProductUIModel = {
	ui: any;
	data: ProductUIDataModel;
};

export enum VENDOR_CODES {
	CLL = 'CLL',
}

export enum FooterBarWhere {
	HOME = 1,
	CATEGORY = 2,
	EXPLORE = 3,
	ME = 4
}

export type PolicyIdentityByQuoteNo = { quoteNo: string | undefined };

export type PolicyIdentityById = { policyId: number | string | undefined };

export type PolicyIdentityBySignatureId = { signatureId: number | string | undefined };

export type ProductIdentity = {
	productCode: string;
	productVersion: string;
	vendorCode: string;
	tenantCode?: string;
	productSubVersion?: string;
};

export type ProductModel = {
	quoteUrl?: string;
	viewUrl?: string;
	rootVendorCode?: string;
	rootVendorName?: string;
	firstProductType?: FirstProductType;
	productType?: string;
} & ProductIdentity;

export type ProductSupport = {
	product: ProductModel;
	type: ProductSupportType;
	externalUrl?: string;
};

export type PolicyPageFlags = {
	BACK?: boolean;
	QUIT?: boolean;
	FAV?: boolean;
	SHARE?: boolean;
	STEP_ON_POLICY?: boolean;
	ADVICE_PERMITTED?: boolean;
	QUOTE_PERMITTED?: boolean;
	VIEW_PERMITTED?: boolean;
	READ_ONLY?: boolean;
};


export const AllSteps = {
	INTRO: 'intro',
	PARTICIPANTS: 'participants',
	COVERAGES: 'coverages',
	ADVICE: 'advice',
	CQ_QUESTIONAIRE: 'cq-questionaire',
	HOLDER_FILL: 'holder-fill',

	INSUREDS_FILL: 'insureds-fill',
	RISK_ASSESSMENT: 'risk-assessment',
	AGENT_DECLARATIONS: 'agent-declarations',
	HOLDER_DECLARATIONS: 'holder-declarations',
	INSURED_DECLARATIONS: 'insured-declarations',
	HOLDER_RISK_QUESTIONS: 'holder-risk-questions',
	WNX_DECLARATIONS: 'wnx-declarations',
	HOLDER_FOREIGN_QUESTIONS: 'holder-foreign-questions',
	INSURED_FOREIGN_QUESTIONS: 'insured-foreign-questions',
	INSURED_EXPERIENCE_DECLARATIONS: 'insured-experience-declarations',
	CUSTOMER_QUESTIONNAIRE: 'customer-questionnaire',
	IMAGE_UPLOAD: 'image-upload',
	REVIEW_ORDER: 'review-order',
	UNDERWRITE_FAILED: 'underwrite-failed',

	AUTHORIZATION_AND_NOTICE: 'authorization-and-notice',
	APPLY_NOTICE: 'apply-notice',
	PRODUCT_CLAUSE: 'product-clause',
	CUSTOMER_NOTICE: 'customer-notice',
	INSURANCE_PROMPT: 'insurance-prompt',
	PAYMENT_AGREEMENT: 'payment-agreement',
	INFORMED_CONSENT_FOR_DEDUCTION: 'informed-consent-for-deduction',
	EXEMPTION_LIABILITY: 'exemption-liability',

	SIGNATURE: 'signature',
	APPLY_FAILED: 'apply-failed',
	ISSUE_FAILED: 'issue-failed',
	PAY_FAILED: 'pay-failed',
	DOCUMENT_UPLOAD_FAILED: 'document-upload-failed',
	ISSUE_SUCCESS: 'issue-success',

	/**电子签名 */
	ELECTRONIC_INSURANCE_FORM: 'electronic-insurance-form',
	AGENT_ELECTRONIC_INSURANCE_FORM: 'agent-electronic-insurance-form',
	HOLDER_ELECTRONIC_INSURANCE_FORM: 'holder-electronic-insurance-form',
	INSURED_ELECTRONIC_INSURANCE_FORM: 'insured-electronic-insurance-form',
	PERSONAL_INSURANCE_REMINDER: 'personal-insurance-reminder',
	AGENT_PERSONAL_INSURANCE_REMINDER: 'agent-personal-insurance-reminder',
	HOLDER_PERSONAL_INSURANCE_REMINDER: 'holder-personal-insurance-reminder',
	PERSONAL_TAX_RESIDENT: 'personal-tax-resident',
	FOREIGN_QUESTION_SURVEY: 'foreign-question-survey',
	CASE_DETAIL_SELECTION: 'case-detail-selection',
	LIABILITY_EXEMPTION_CLAUSES: 'liability-exemption-clauses',
	AGENT_CLIENT_NOTICE: 'agent-client-notice',
	HOLDER_CLIENT_NOTICE: 'holder-client-notice',
	CLIENT_NOTICE: 'client-notice',
	AGENT_CONSIGNMENT_AGREEMENT: 'agent-consignment-agreement',
	HOLDER_CONSIGNMENT_AGREEMENT: 'holder-consignment-agreement',
	CONSIGNMENT_AGREEMENT: 'consignment-agreement',
	AGENT_ELECTRONIC_INSURANCE_FORM_SIGNATURE: 'agent-electronic-insurance-form-signature',
	HOLDER_ELECTRONIC_INSURANCE_FORM_SIGNATURE: 'holder-electronic-insurance-form-signature',
	INSURED_ELECTRONIC_INSURANCE_FORM_SIGNATURE: 'insured-electronic-insurance-form-signature',
	HOLDER_PERSONAL_TAX_RESIDENT_SIGNATURE: 'holder-personal-tax-resident-signature',
	HOLDER_PRODUCT_STATEMENT: 'holder-product-statement',

	HOLDER_CASE_DETAIL_SELECTION_SIGNATURE: 'holder-case-detail-selection-signature',
	INSURED_CASE_DETAIL_SELECTION_SIGNATURE: 'insured-case-detail-selection-signature',

	AGENT_PERSONAL_INSURANCE_REMINDER_SIGNATURE: 'agent-personal-insurance-reminder-signature',
	HOLDER_PERSONAL_INSURANCE_REMINDER_SIGNATURE: 'holder-personal-insurance-reminder-signature',
	HOLDER_LIABILITY_EXEMPTION_CLAUSES_SIGNATURE: 'holder-liability-exemption-clauses-signautre',
	HOLDER_CLIENT_NOTICE_SIGNATURE: 'holder-client-notice-signature',
	AGENT_CONSIGNMENT_AGREEMENT_SIGNATURE: 'agent-consignment-agreement-signature',
	HOLDER_CONSIGNMENT_AGREEMENT_SIGNATURE: 'holder-consignment-agreement-signature',
	HOLDER_PERSON_INFORMATION_AUTHORIZATION: 'holder-person-information-authorization',
	HOLDER_INSURANCE_SPECIFICATION: 'holder-insurance-specification',
    INSURED_INSURANCE_AUTHORIZATION_STATEMENT:'insured-insurance-authorization-statement',

	PERSON_INFO_AUTHOR_HOLDER: 'person_info_author_holder',
	PERSON_INFO_AUTHOR_INSURED: 'person_info_author_insured',
	CUSTOMER_INFO_AUTHOR_HOLDER: 'customer_info_author_holder',
	CUSTOMER_INFO_AUTHOR_INSURED: 'customer_info_author_insured',
	PERSON_INFO_AUTHOR_HOLDER_SIGNATURE: 'person_info_author_holder_signature',
	PERSON_INFO_AUTHOR_INSURED_SIGNATURE: 'person_info_author_insured_signature',

	AGENT_DECLARATION_POLICY: 'agent_declaration_policy',
	AGENT_Declaration_SIGNATURE: 'agent_declaration_signature',

	AGENT_PREMIUM_TRANSFER_STATEMENT: 'agent-bank-premium-transfer',
	HOLDER_PREMIUM_TRANSFER_STATEMENT: 'holder-bank-premium-transfer',
	INSURED_PREMIUM_TRANSFER_STATEMENT: 'insured-bank-premium-transfer',

	AGENT_PREMIUM_TRANSFER_STATEMENT_CSMODE: 'agent-bank-premium-transfer-csmode',
	HOLDER_PREMIUM_TRANSFER_STATEMENT_CSMODE: 'holder-bank-premium-transfer-csmode',
	INSURED_PREMIUM_TRANSFER_STATEMENT_CSMODE: 'insured-bank-premium-transfer-csmode',

	HOLDER_RELATION_STATEMENT: 'holder-relation-statement',
	INSURED_RELATION_STATEMENT: 'insured-relation-statement',

	HOLDER_APPLY_NOTICE: 'holder-apply-notice',
	HOLDER_WNX_CONFIRM_NOTICE: 'holder-wnx-confirm-notice',
	INSURED_WNX_CONFIRM_NOTICE: 'insured-wnx-confirm-notice',

	AUTHORIZATION_AND_NOTICE_HOLDER_SIGN: 'authorization-and-notice-holder-sign',

	PAYMENT: 'payment',
	HOLDER_QUESTIONNAIRE: 'holder_questionnaire',
	INSURED_QUESTIONNAIRE: 'insured_questionnaire',
	DOUBLE_RECORDING_QRCODE: 'double_recording_qrcode',

	HOLDER_INSURANCE_INSTRUCTIONS: 'holder-insurance-instructions',
	HOLDER_DECLARATIONS_AND_AUTHORIZATION: 'holder-declarations-and-authorization',
	HOLDER_UNREGISTERED_CHILDREN_INSURANCE_DECLARATION: 'holder-unregistered-children-insurance-declaration',
	INSURED_DECLARATIONS_AND_AUTHORIZATION: 'insured-declarations-and-authorization',
	INSURED_UNREGISTERED_CHILDREN_INSURANCE_DECLARATION: 'insured-unregistered-children-insurance-declaration',

	SMS_VALIDATION: 'sms-validation',
	SIGN_RECEIPT: 'sign-receipt',
	RECEIPT_SIGNATURE: 'receipt-signature',
	SIGN_REVIEW_ORDER: 'sign-review-order',
	SIGN_SIGNATURE: 'sign-signature',
	SIGN_RECEIPT_SIGNATURE_SUCCESS: 'sign-receipt-signature-success',


	HOLDER_FOREIGN_QUESTION_SURVEY_SIGNATURE: 'holder-foreign-question-survey-signature',
	INSURED_FOREIGN_QUESTION_SURVEY_SIGNATURE: 'insured-foreign-question-survey-signature',

	HOLDER_SIGNATURE: 'holder-signature',
	INSURED_SIGNATURE: 'insured-signature',
	AGENT_SIGNATURE: 'agent-signature',
	SIGNATURE_SUCCESS: 'signature-success',

	CHONGQING_QUESTION_SURVEY: 'chongqing-question-survey',
	AGENT_CHONGQING_QUESTION_SURVEY_SIGNATURE: 'agent-chongqing-question-survey-signature',
	HOLDER_CHONGQING_QUESTION_SURVEY_SIGNATURE: 'holder-chongqing-question-survey-signature',


	HOLDER_CHONGQING_QUESTION_SURVEY: 'holder-chongqing-question-survey',
	AGENT_CHONGQING_QUESTION_SURVEY: 'agent-chongqing-question-survey',

	POLICY_INFO: 'policy-info',
	VIEW_SIGNATURE: 'view-signature',
	PAY_SUCCESS: 'pay-success',
	VIEW_RECEIPT: 'view-receipt',
	RECEIPT_SIGNATURE_SUCCESS: 'receipt-signature-success',
	WITHDRAW_SIGNATURE_SUCCESS: 'withdraw-signature-success',
	IMAGE_REUPLOAD: 'image-reupload',
	CHANGE_BANK_INFO: 'change-bank-info',
	CHANGE_BANK_SIGNATURE: 'change-bank-signature',
	CHANGE_BANK_SIGNATURE_AGENT: 'change-bank-signature-agent',
	CHANGE_BANK_SIGNATURE_HOLDER: 'change-bank-signature-holder',
	CHANGE_BANK_SIGNATURE_INSURED: 'change-bank-signature-insured',
	CHANGE_BANK_IMAGE: 'change-bank-image',
	CHANKBANK_SUCCESS: 'change-bank-success',
	CHANKBANK_FAIL: 'change-bank-fail',

	SUBMITED: 'submited',
	WITHDRAW_SUCCESS: 'withdraw-success',
	WITHDRAW_QUESTION: 'withdraw-question',
	WITHDRAW_SIGNATURE: 'withdraw-signature',
	WITHDRAW_SIGNATURE_HOLDER: 'withdraw-signature-holder',

	// 风险评测问卷（通用）
	SIGN_RISK_QUESTION: 'sign-risk-question',
	SIGN_RISK_QUESTION_SUCCESS: 'sign-risk-question-success',

    PERSONAL_RETIREMENT_BIND: 'personal-retirement-bind',
	//影像补传
    QUALITY_INSPECTION_RESULT: 'quality-inspection-result',
    REIMAGE_UPLOAD: 'reimage-upload',
    REIMAGE_UPLOAD_READONLY: 'reimage-upload-readonly',
    RESIGNATURE: 'resignature',
    REHOLDER_SIGNATURE: 'reholder-signature',
    REINSURANCE_SIGNATURE: 'reinsurance-signature',
};

// 前缀合集-不允许再继续在保司内部自定义，统一从这里拿
/** 主被保人标的属性名称, 绝对位置 */
export const mainInsuredId = 'insureds[!extensionData.mainInsured=Y]';
/** 标的被保人属性名称, 相对于标的的相对位置 */
export const mainInsuredParticipantIdToInsured = 'participants[!customerType=2]';
export const mainInsuredParticipantIdToBeneficiary = 'participants[customerType=3]';
/** 主险 */
export const mainCoverage = "coverages[!extensionData.coverageType=M]"
export const mainInsuredMainCoverageIdToPolicy = `${mainInsuredId}.${mainCoverage}`
/** 主被保人标的被保人属性名称, 绝对位置 */
export const mainInsuredParticipantIdToPolicy = `${mainInsuredId}.${mainInsuredParticipantIdToInsured}`;
/** 主被保人标的被保人与投保人关系属性名称, 绝对位置 */
export const mainInsuredRelationWithPolicyHolderPropName = `${mainInsuredParticipantIdToInsured}.extensionData.relationWithPH`;
export const mainInsuredRelationWithBeneficiaryPropName = `${mainInsuredParticipantIdToBeneficiary}.extensionData.relationWithInsured`;
export const mainInsuredRelationWithPolicyHolderFromRootPropName = `${mainInsuredParticipantIdToPolicy}.extensionData.relationWithPH`;
export const isPolicyHolderExpression = `${mainInsuredRelationWithPolicyHolderPropName}=${RelationTypeWith.SELF}`;
export const isPolicyHolderExpressionFromRoot = `${mainInsuredRelationWithPolicyHolderFromRootPropName}=${RelationTypeWith.SELF}`;
export const isNotPolicyHolderExpression = `${mainInsuredRelationWithPolicyHolderPropName}!=${RelationTypeWith.SELF}`;
export const isNotPolicyHolderExpressionFromRoot = `${mainInsuredRelationWithPolicyHolderFromRootPropName}!=${RelationTypeWith.SELF}`;
export const cellHolderCreationParameter = { prefix: 'policyHolder' };
export const cellHolderTaxCreationParameter = { taxPrefix: 'policyHolder', prefix: 'policyHolder.extensionData.taxDweller' };
export const cellHolderCreationParameterReadOnly = { prefix: 'policyHolder', readOnly: true };
export const cellHolderTaxCreationParameterReadOnly = { taxPrefix: 'policyHolder', prefix: 'policyHolder.extensionData.taxDweller', readOnly: true };
export const cellParticipantCreationParameter = {
	prefix: mainInsuredParticipantIdToInsured,
	visibleOnRelationWithPolicyHolder: isNotPolicyHolderExpression,
	notSyncToInsureds: true,
};
export const cellParticipantTaxCreationParameter = { taxPrefix: mainInsuredParticipantIdToInsured, prefix: `${mainInsuredParticipantIdToInsured}.extensionData.taxDweller` };
export const beneficiaryDeathPropName = `participants[customerType=${PolicyCustomerType.BENEFICIARY}&&extensionData.beneficiaryType=${BeneficiaryType.DEATH}]`;
export const sameAsPolicyHolderPropName = 'extensionData.sameWithPH';
export const mainInsuredBeneficiarySameWithPH = `${mainInsuredId}.participants[customerType=${PolicyCustomerType.BENEFICIARY}&&${sameAsPolicyHolderPropName}=Y]`;
/** 选中的方案code, 绝对位置 */
export const planCodeToPolicy = `extensionData.planCode`;
export const coverageToPolicy = `${mainInsuredId}.coverages[0]`;
export const liabilityListToPolicy = `${coverageToPolicy}.extensionData.liabilityList`;
export const mainInsuredBeneficiaryParticipantsToPolicy = `${mainInsuredId}.${mainInsuredParticipantIdToBeneficiary}`;
/**
 * ui页面驱动参数
 */
export type UIWorkflowParams = {
	// 当前页面
	step: string;
	// 步骤名称
	stepName?: string;
	// 步骤大类，用于头部进度条展示
	stepIndex?: number;
	// 下一步页面
	toStep?: string;
	// 上一步页面
	backStep?: string;
	// 产品名和图片
	titleAndLogo?: any;
	// 从哪个页面来，用于公共step区分
	where?: 'QUOTE' | 'SIGN' | 'VIEW',
	// 上一页自定义行为
	pageBackAction?: (invoker: ModelWidgetInterface & WidgetHelped) => any;
	// 下一页自定义行为
	pageToAction?: (invoker: ModelWidgetInterface & WidgetHelped) => any;
	// 上一步补充行为
	backClickAction?: (invoker: ModelWidgetInterface & WidgetHelped) => any;
	// 下一步补充行为
	nextClickAction?: (invoker: ModelWidgetInterface & WidgetHelped) => any;
	// 页面自定义初始化行为
	processAction?: (invoker: ModelWidgetInterface & WidgetHelped) => any;
	// 自定义校验器（一般别用，自定义特殊的校验）
	customizeChecker?: (invoker: ModelWidgetInterface & WidgetHelped) => any;
}

/**
 * 单证签名驱动参数
 */
export type UIDocumentParams = {
	// 单证模型坐标
	propName?: string;
	// 是否需要校验单证是否读完（只有一页的单证要设置false）
	checkReadComplete?: boolean;
	// 单证阅读坐标，用于区分展示单证和阅读标记位不同的情况，和propName一样的话就不要传
	realPropName?: string;
	// 前缀
	prefix?: string;
	// 单证类型枚举
	documentType?: string;
	// 单证模板类型-freemarker枚举
	signatureDocumentType?: string;
	// 谁在读单证
	who?: 'AGENT' | 'HOLDER' | 'INSURED';
	// 是否展示
	visible?: boolean | string | string[] | Filterable | Filterable[];
	// 单证状态
	readMarkPrefix?: string;
	// 是否有风险语录按钮
	showRiskWords?: boolean | ((invoker: ModelWidgetInterface & WidgetHelped) => boolean);
	// 风险语录
	riskWords?: string;
	// 险种代码
	coverageCode?: string;
	// 签字图片
	signImages?: string;
	// 阅读按钮属性
	readBtnProps?: MobileButtonProps;
}

/**
 * 原子业务组件入参
 */
export type AtomicComponentParams = {
	// 栏位名
	label?: string;
	// 栏位所处位置
	id?: string;
	// 是否必传
	required?: boolean,
	// 外部注入校验器
	checker?: any,
	// 是否只读
	readonly?: string | boolean | any;
	// 监听器
	listener?: (options: ForceListenEvent) => void;
	// 是否可见
	visible?: boolean | any;
	//codes过滤
	codesFilter?: CodesFilter;
	// 枚举值
	codes?: CodeItem[];
	// 如果要改样式
	css?: (props?:any, theme?: StyledPropsTheme) => string;
	//底纹
	placeholder?: string;
	// 显示导入家庭成员
	customerImportEnabled?: boolean;
	// 前缀
	prefix?: string;
}

export enum UserRoleType {
	/** 投保人 */
	HOLDER = '1',
	/** 被保人 */
	INSURED = '2',
	/** 受益人 */
	BENEFICIARY = '3',
	/** 代理人 */
	AGENT = '4',
	/** 监护人 */
	GUARDIAN = '5',
}

export type UploadDocument = {
	documentType: PolicyDocumentType | string;
	userRole?: UserRoleType;
	quotationNo: string;
	roleId?: string;
	documentId?: number;
	front?: boolean;
	ossFileKey?: string;
	idType?: string;
	idNo?: string;
	coverageCode?: string;
	previewUrl?: string;
	readDocument?: ReadDocumentStatus;
	signImage?: string;
	signDate?: string;
	url?: string;
	uri?: string;
	extensionData?: any;
};

export enum ReadDocumentStatus {
	/** 已阅读 */
	READ = '1',
	/** 未阅读 */
	UNREAD = '2',
	/** 已签名 */
	SIGNED = '3'
}

export enum InstallmentFrequency {
	SINGLE_PREMIUM = '5',
	YEARLY = '1',
	HALF_YEAR = '2',
	QUARTERLY = '3',
	MONTHLY = '4',
	IRREGULAR = '-1',
}

export enum DeclarationRoleType {
	AGENT = "AGENT",// "代理人"),
	PROPOSER = "PROPOSER", //"投保人"),
	INSURED = "INSURED", //"被保人"),
}

export enum DeclarationType {
	DH_COMMON = "DH_COMMON", // 通用告知
	DH_SPECIAL = "DH_SPECIAL", // 特殊告知
	DH_NORMAL = "DH_NORMAL", // 简易告知
	DO_AGENT = "DO_AGENT", // 业务员告知
	DO_FINANCIAL = "DO_FINANCIAL", // 财务告知
	QN_NON_MAIN_LANDERS = "QN_NON_MAIN_LANDERS", // 非大陆人人士问卷
	QN_INFANT = "QN_INFANT", // 婴幼儿告知问卷
	QN_CHONGQING = "QN_CHONGQING", // 重庆需求问卷
	QN_SICHUAN = "QN_SICHUAN", // 四川需求问卷
	QN_RISK_COMMON = "QN_RISK_COMMON", // 通用风险评测问卷
	QN_RISK_INVEST = "QN_RISK_INVEST", // 新型产品风险评测问卷
	QN_RISK_ANNUITY = "QN_RISK_ANNUITY", // 年金产品风险评测问卷
	L_UW_DECISION = "L_UW_DECISION", // 核保决定通知书
	L_UW_SUPPLEMENTARY = "L_UW_SUPPLEMENTARY", // 补充资料通知书
	L_UW_NOTICE = "L_UW_NOTICE", // 契调通知书
	L_UW_PHYSICAL_EXAMINATION = "L_UW_PHYSICAL_EXAMINATION", // 体检通知书
	L_UW_FINANCIAL = "L_UW_FINANCIAL", // 核保财务问卷
	L_NB_CHANGE_INFO = "L_NB_CHANGE_INFO", // 新契约变更通知书
	L_UW_HEALTH_NOTICE = "L_UW_HEALTH_NOTICE", // 核保健康问卷
	L_NB_QUALITY_INSPECT = "L_NB_QUALITY_INSPECT", // 质检作业通知书
	L_TRANSFER_UNSUCCESSFUL = "L_TRANSFER_UNSUCCESSFUL", // 转账不成功通知书
	L_QUES_JOB = "L_QUES_JOB", // 核保职业问卷
	L_QUES_RISK_LERANCE = "L_QUES_RISK_LERANCE", // 风险承受能力评估
	L_QUES_HYPERTENSION = "L_QUES_HYPERTENSION", // 高血压问卷
	L_QUES_HYPERGLYCEMIA = "L_QUES_HYPERGLYCEMIA", // 高血糖问卷
	L_QUES_FRACTURE = "L_QUES_FRACTURE", // 骨折问卷
	L_QUES_TUBERCULOSIS = "L_QUES_TUBERCULOSIS", // 结核病问卷
	L_QUES_ASTHMA = "L_QUES_ASTHMA", // 哮喘问卷
}

export enum QuesComponentType {
	NONE = "none",// "非组件"), //作用于不需要回答的类型
	TEXT = "text", // "普通文本"),
	TEXT_AREA = "textarea",// "多行文本"),
	TEXT_NUMBER = "number",// "数字文本"),
	TEXT_DATE = "date", //"日期文本"),
	CHECKBOX = "checkbox",// "复选"),
	SINGLE_CHECKBOX = "single_checkbox",// "复选-仅能选一个"),
	RADIO = "radio",// "单选"),
	UPLOAD = "upload",//"上传文件"),
    LIST = "list",//"下拉弹框"),
    GROUP = "group",//"组"),
    SELECT = "select",//"选择框"),
}

export enum DeclarationSceneType {
	/** 被保险人告知 */
	INSURED_DECLARATION = "INSURED_DECLARATION",
	/** 投保人告知 */
	HOLDER_DECLARATION = "HOLDER_DECLARATION",
}

export type RiderCoverage = {
    code: string;
    name: string;
    step: string;
    init: any;
    excludes?: string | string[];
};

export type CoverageAvoider = (coverage: CoverageItem, insured: any, policy: any) => string | void;
